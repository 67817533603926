import React, { useEffect, useRef } from 'react'
import { detect } from 'detect-browser'
import classNames from 'classnames'

const browser = detect()

const Hero = (props) => {
  const ref = useRef()

  useEffect(() => {
    let looping = browser.os === 'iOS'

    const loop = () => {
      if (!looping) return false

      if (ref.current) {
        const height = `${window.innerHeight}px`
        ref.current.style.minHeight = height
        ref.current.style.maxHeight = height
        ref.current.style.height = height
      }

      window.requestAnimationFrame(loop)
    }

    loop()

    return () => (looping = false)
  })

  return (
    <div ref={ref} className={classNames('hero', props.classes)}>
      {
        props.head
          ? (
            <div className={classNames('hero-head', 'title-variant', props.headClasses)}>
              {props.head}
            </div>
          )
          : undefined
      }
      <div className={classNames('hero-body', props.innerClasses)}>
        {props.children}
      </div>
      {
        props.foot
          ? (
            <div className={classNames('hero-foot', props.footClasses)}>
              {props.foot}
            </div>
          )
          : undefined
      }
    </div>
  )
}

export default Hero
