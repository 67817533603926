const initialState = {
  open: false,
  name: '',
  data: null,
  cancelable: true
}

const modalReducer = (state = initialState, action) => {
  const type = action.type

  if (type === 'modal.close') return { ...state, open: false, data: null }
  if (type === 'modal.open') return { ...state, open: true, name: action.name, data: action.data, cancelable: (typeof action.cancelable === 'boolean') ? action.cancelable : true }

  return state
}

export default modalReducer
