import * as yup from 'yup'

const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
const zipRegExp = /^\d{5}(-\d{4})?$/

const schema = yup.object().shape({
  location: yup.number('Not a number').required('Restaurant location').integer('Not valid').min(1, 'Not valid').max(3, 'Not valid'),
  items: yup.array().required('Order items'),
  tip: yup.number().required('Did not receive tip amount'),
  fulfillment: yup.mixed().oneOf(['Pickup', 'Delivery'], 'Please choose one of the given values').required('Delivery or pickup'),
  date: yup.date().typeError('Please enter a valid date').required('Date of pick up / delivery'),
  time: yup.string().required('Time of pick up / delivery').min(5, 'Invalid time format, too short').max(11, 'Invalid time format, too long'),
  firstname: yup.string('Letters only').required('First name').min(2, 'Min. 2 chars').max(50, 'Max. 50 chars'),
  lastname: yup.string('Letters only').required('Last name').min(2, 'Min. 2 chars').max(50, 'Max. 50 chars'),
  email: yup.string('Email Address').required('Email').email('Enter a valid email address'),
  phone: yup.string().required('Phone number').matches(phoneRegExp, 'Enter a valid phone number').min(10, 'Enter a valid phone number').max(20, 'Enter a valid phone number'),
  company: yup.string('Letters only').min(5, 'Min. 5 chars').max(100, 'Max. 100 chars'),
  address1: yup.string('Letters only').when('fulfillment', {
    is: 'Delivery',
    then: yup.string('Letters only').required('Delivery address (line 1)').min(5, 'Min. 5 chars').max(100, 'Max. 100 chars'),
    otherwise: yup.string('Letters only').min(5, 'Min. 5 chars').max(100, 'Max. 100 chars')
  }),
  address2: yup.string('Letters only').max(100, 'Max. 100 chars'),
  city: yup.string('Letters only').when('fulfillment', {
    is: 'Delivery',
    then: yup.string('Letters only').required('Delivery city').min(3, 'Min. 3 chars').max(100, 'Max. 100 chars'),
    otherwise: yup.string('Letters only').min(3, 'Min. 3 chars').max(100, 'Max. 100 chars')
  }),
  zip: yup.string().when('fulfillment', {
    is: 'Delivery',
    then: yup.string().required('Delivery zip').matches(zipRegExp, 'Enter a valid ZIP/Postal code').min(5, 'Enter a valid ZIP/Postal code').max(10, 'Enter a valid ZIP/Postal code'),
    otherwise: yup.string().matches(zipRegExp, 'Enter a valid ZIP/Postal code').min(5, 'Enter a valid ZIP/Postal code').max(10, 'Enter a valid ZIP/Postal code')
  }),
  comment: yup.string('Letters only').min(10, 'Min. 10 chars').max(100, 'Max. 100 chars'),
  recaptcha: yup.string().required("I'm not a robot verification")
})

export default schema
