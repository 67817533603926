import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'

class NumberInput extends React.Component {
  render () {
    return (
      <div className={classNames('number-input field has-addons', {
        'number-input--small': this.props.small
      }, this.props.classes)}
      >
        <div className='control'>
          <button
            className={classNames('button is-dark', {
              'is-small': this.props.small
            })} onClick={this.props.onDecrease} disabled={this.props.minusDisabled}
          >
            <FontAwesomeIcon icon={faMinus} className='icon' />
          </button>
        </div>
        <div className='control'>
          <input
            type='number'
            className={classNames('input number-input__input', {
              'is-small': this.props.small
            })} value={
              !isNaN(this.props.value) ? this.props.value : 0
            } onChange={(event) => {
              if (!isNaN(event.target.value) && this.props.onChange) this.props.onChange(event)
            }} onFocus={ev => ev.target.select()}
            readOnly={this.props.readOnly}
          />
        </div>
        <div className='control'>
          <button
            className={classNames('button is-dark', {
              'is-small': this.props.small
            })} onClick={this.props.onIncrease} disabled={this.props.plusDisabled}
          >
            <FontAwesomeIcon icon={faPlus} className='icon' />
          </button>
        </div>
      </div>
    )
  }
}

export default NumberInput
