const config = {
  apiUrl: 'https://api.andalousgrill.com',
  // apiUrl: 'http://localhost:8080',
  maxSingleItems: 1000, // this is the maximum of any specific item you can order
  maxTotalItems: 1000, // maximum amount of items in total
  maxCateringItems: 999,
  minCateringItems: 12,
  minimumSubtotalAmount: 4.99
}

export default config
