const initialState = {
  loaded: [],
  values: {}
}

const deduplicate = (value, index, array) => array.indexOf(value) === index

const loadersReducer = (state = initialState, action) => {
  const type = action.type

  if (type === 'loaders.load') {
    return {
      ...state,
      loaded: state.loaded.concat([action.name]).filter(deduplicate),
      values: {
        ...state.values,
        [action.name]: action.value
      }
    }
  }

  return state
}

export default loadersReducer
