import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretLeft, faCaretRight, faCheck, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'

import createAction from '../actions'
import { loadMenu } from '../actions/loaders'

import { OrderLayout, IconCards, Section, Level, withDependencies } from '../containers'
import { OrderPreview, LoadingScreen, IconCard } from '../components'

class PlatterPage extends React.Component {
  constructor () {
    super()

    this.onBack = () => {
      if (this.props.currentIndex) this.props.back()
      else this.props.history.push('/')
    }
    this.onNext = () => {
      if (this.props.currentIndex >= this.props.platter.config.items.length - 1) {
        const { platter, selections, menu } = this.props

        const itemsById = {}
        menu.items.forEach(item => (itemsById[item.id] = item))

        const mappedSelections = selections.map(category => category.map(id => itemsById[id]))

        this.props.addToCart({ ...platter, items: mappedSelections })
        this.props.clear()
      } else this.props.next()
    }
  }

  componentDidMount () {
    this.props.loadDependencies()
  }

  render () {
    const { menu, orderLocation, platter, selections, history, currentIndex, order } = this.props
    if (!platter) {
      setImmediate(() => history.push('/'))
      return <LoadingScreen />
    }

    const config = platter.config
    const itemConfig = config.items[currentIndex]

    const richardsonFilter = item => {
      if (orderLocation.name === 'Richardson' && item.disabledAtRichardson) return false // HARDCODED

      return true
    }

    const itemsAvailable = Array.isArray(itemConfig.category) ? (
      itemConfig.category.map(category => menu.platterMenu[category]).flat().filter(richardsonFilter)
    ) : menu.platterMenu[itemConfig.category].filter(richardsonFilter)
    const currentSelections = selections[currentIndex]

    const singleSelect = itemConfig.quantity === 1
    const lastSelection = this.props.currentIndex >= this.props.platter.config.items.length - 1

    const overflow = currentSelections.length > itemConfig.quantity
    const canContinue = currentSelections.length === itemConfig.quantity // && !overflow

    return (
      <OrderLayout
        title={
          <>
          Pick up for {order.name} at {order.location.name}
          </>
        } order={<OrderPreview />} titleSection={
          <Level
            classes='is-mobile platter-page__title' left={
              <button className='button platter-page__button' onClick={this.onBack}> {/* eslint-disable-line */}
                <FontAwesomeIcon icon={faCaretLeft} />
                &nbsp;
                Back
              </button>
            } right={
              <button className='button platter-page__button is-primary' onClick={this.onNext} disabled={!canContinue}> {/* eslint-disable-line */}
                {lastSelection ? 'Finish' : 'Next'}
                &nbsp;
                <FontAwesomeIcon icon={faCaretRight} />
              </button>
            }
          >
            <div className='platter-page__category-title'>
              {itemConfig.title}
            </div>
          </Level>
        }
      >
        <Section classes='platter-page__section'>
          <IconCards cards={
            itemsAvailable.map(item => {
              const itemIndices = currentSelections.map((item, index) => ({ item, index })).filter(v => v.item === item.id).map(v => v.index + 1)
              const itemSelected = itemIndices.length

              const overflowing = itemIndices.filter(index => index > itemConfig.quantity).length

              return (
                <IconCard
                  key={item.id}
                  classes='platter-page__card'
                  innerClasses='platter-page__card-content'
                  imageClasses='platter-page__card-image button'
                  image={item.image}
                  imageOverlay={
                    <div className={classNames('platter-page__card-overlay', {
                      'platter-page__card-overlay--selected': itemSelected,
                      'platter-page__card-overlay--overflow': overflowing
                    })}
                    >
                      {
                        itemSelected && singleSelect
                          ? <FontAwesomeIcon icon={faCheck} />
                          : itemIndices.join(' & ')
                      }
                      {
                        overflowing ? (
                          <div className='platter-page__card-overflow'>
                            <div className='level is-mobile'>
                              <div className='level-left'>
                                <FontAwesomeIcon icon={faExclamationTriangle} size='2x' />
                              </div>
                              <div>&nbsp;&nbsp;</div>
                              <div className='level-right'>
                                TOO MANY
                                <br />
                                SELECTIONS
                              </div>
                            </div>
                          </div>
                        ) : undefined
                      }
                    </div>
                  }
                  onClick={() => itemSelected >= (singleSelect ? 1 : 2) ? this.props.deselect(item.id) : this.props.select(item.id)}
                  disabled={!itemSelected && overflow}
                  platter
                >
                  {item.name}
                </IconCard>
              )
            })
          }
          />
        </Section>
      </OrderLayout>
    )
  }
}

export default connect(
  state => ({
    orderLocation: state.order.location,
    platter: state.platter.platter,
    selections: state.platter.selections,
    currentIndex: state.platter.currentIndex,
    order: state.order
  }),
  dispatch => ({
    loadDependencies: () => dispatch(loadMenu),
    back: () => dispatch(createAction('platter.back')),
    next: () => dispatch(createAction('platter.next')),
    select: (item) => dispatch(createAction('platter.select', { item })),
    deselect: (item) => dispatch(createAction('platter.deselect', { item })),
    deselectOne: (item) => dispatch(createAction('platter.deselect-one', { item })),
    addToCart: (item) => dispatch(createAction('order.addItem', { item })),
    clear: () => dispatch(createAction('platter.clear'))
  })
)(withRouter(withDependencies(PlatterPage, ['menu'], { includeProps: true })))
