import React from 'react'
import classNames from 'classnames'

class Level extends React.Component {
  render () {
    return (
      <div className={classNames('level', this.props.classes)}>
        <div className={classNames('level-left', this.props.leftClasses)}>
          {this.props.left}
        </div>
        {this.props.children}
        <div className={classNames('level-right', this.props.rightClasses)}>
          {this.props.right}
        </div>
      </div>
    )
  }
}

export default Level
