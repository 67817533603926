import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { CateringNavbar } from '../components'
import { Hero, Container } from '.'

class CateringPage extends React.Component {
  render () {
    const currentYear = new Date().getFullYear()

    return (
      <div className='catering-page'>
        <Helmet>
          <title>Catering | Andalous Mediterranean Grill</title>
          <meta property='og:title' content='Andalous Mediterranean Grill Catering' />
          <meta property='og:type' content='article' />
          <meta property='og:description' content='Andalous Mediterranean Grill offers catering for large groups and events.' />
          <meta property='og:url' content='https://order.andalousgrill.com/catering' />
          <meta property='og:image' content='https://andalous-website.s3.us-east-2.amazonaws.com/image-uploads-manual/Andalous-OG-Catering.png' />
        </Helmet>
        <CateringNavbar />
        <Hero
          classes='is-fullheight-with-navbar'
          footClasses='catering-page__foot'
          foot={
            <>
              © {currentYear} Andalous Mediterranean Grill, LLC, All&nbsp;Rights&nbsp;Reserved
              <br />
              The Andalous Logo is a registered trademark of Andalous Mediterranean Grill, LLC
              <br />
              Copying or duplication of images contained herein is strictly forbidden without the prior written consent of Andalous Mediterranean Grill, LLC
              <br />
              <Link to='/privacy-policy'>Privacy Policy</Link> | <Link to='/terms-of-service'>Terms of Service</Link>
            </>
          }
          innerClasses={this.props.containerClasses || ''}
        >
          <Container classes={this.props.innerContainerClasses || ''}>
            {this.props.children}
          </Container>
        </Hero>
      </div>
    )
  }
}

export default CateringPage
