import React from 'react'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'

import { Level } from '../containers'
import { BlippedButton } from '.'

class TitleBar extends React.Component {
  constructor () {
    super()

    this.handleScrollToCart = () => {
      const cartColumn = document.querySelector('.order-layout__order-column')
      if (cartColumn) cartColumn.scrollIntoView({ behavior: 'smooth' })
    }
  }

  render () {
    return (
      <Level
        classes='title-bar' leftClasses='title-bar__left' left={
          <>
            <a href='https://www.andalousgrill.com' className='navbar-item'>
              <img className='title-bar__image' src='https://andalous-website.s3.us-east-2.amazonaws.com/image-uploads-manual/andalous-logo-tagged-regged-194w.png' alt='' />
            </a>
            {
              this.props.hasCart
                ? <BlippedButton classes='title-bar__cart-button-container' icon={faShoppingCart} blip={this.props.blip} onClick={this.handleScrollToCart} />
                : undefined
            }
          </>
        }
      >
        <div className='title-bar__title'>
          {this.props.children}
        </div>
      </Level>
    )
  }
}

export default TitleBar
