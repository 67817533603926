import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CateringInCartIndicator = props => {
  return (
    <div className='catering-item__in-cart-indicator-and-remover-wrapper'>
      <button className='catering-item__in-cart-indicator-and-remover button is-small is-primary' type='button' onClick={props.handleRemoval}>
        {props.amount}
      </button>
      <div className='catering-item__in-cart-indicator-and-remover-blip'>
        <FontAwesomeIcon icon='times-circle' />
      </div>
    </div>
  )
}

export default CateringInCartIndicator
