import React from 'react'
import classNames from 'classnames'

import { Container } from '.'

class Section extends React.Component {
  render () {
    return (
      <div className={classNames('section', this.props.classes)}>
        <Container classes={this.props.innerClasses}>
          {this.props.children}
        </Container>
      </div>
    )
  }
}

export default Section
