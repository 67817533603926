import React from 'react'

import { CateringPage, Section, withDependencies } from '../../containers'
import { CateringItem } from '../../components'
import BottomLinks from '../../components/BottomLinks'

class CateringAppetizersPage extends React.Component {
  render () {
    const categories = this.props.catering.cateringCategories

    return (
      <CateringPage containerClasses='is-paddingless'>
        <Section>
          <h2 className='title is-size-4-mobile'>Appetizers</h2>
          <p>Little pieces of heaven. Our selection of appetizers can provide a refeshing twist to traditional finger foods. They are also a great way to start a full meal. <strong>Appetizers require a minimum order of 12 per item.</strong></p>
          {categories.APZR.map(item => <CateringItem item={item} key={item.id} mask={[true]} labels={['Served on a tray']} descriptions={['Min: 12 pieces']} minimums={[12]} />)}
        </Section>
        <Section>
          <h2 className='title is-size-4-mobile'>Mini Sandwiches</h2>
          <p>Our mini sandwiches come wrapped in fresh thin pita. <strong>Mini sandwiches require a minimum order of 12 per item.</strong></p>
          {categories.MINI.map(item => <CateringItem item={item} key={item.id} mask={[true]} labels={['Served on a tray']} descriptions={['Min: 12 pieces']} minimums={[12]} />)}
        </Section>
        <BottomLinks />
      </CateringPage>
    )
  }
}

export default withDependencies(CateringAppetizersPage, ['catering'], { includeProps: true })
