import React from 'react'

import { CateringPage, Section, withDependencies } from '../../containers'
import { CateringItem } from '../../components'
import BottomLinks from '../../components/BottomLinks'

class CateringSaladsPage extends React.Component {
  render () {
    const categories = this.props.catering.cateringCategories

    return (
      <CateringPage containerClasses='is-paddingless'>
        <Section>
          <h2 className='title is-size-4-mobile'>Dips</h2>
          <p>Among the most popular of our menu items. Our dips are irresistible, bite after bite, your guests will enjoy their delicious flavor. We highly recommend a relish tray, pitas or pita chips for dipping.</p>
          {categories.DIPS.map(item => <CateringItem item={item} key={item.id} />)}
        </Section>
        <Section>
          <h2 className='title is-size-4-mobile'>Pitas & Pita Chips</h2>
          <p>We bake our pitas and pita chips fresh everyday in our restaurant. Hot and fresh pitas enhance the flavor of our dips and make the perfect accompaniment to our entrées and salads.</p>
          {categories.PITA.map(item => <CateringItem item={item} key={item.id} />)}
        </Section>
        <Section>
          <h2 className='title is-size-4-mobile'>Relish Tray</h2>
          <p>Our relish tray is the perfect compliment to any of our appetizers, dips, mini sandwiches or meat dishes. Every relish tray inlcudes an assortment of pickles, tomatoes, black & green olives, feta cheese, cilantro, carrots and cucumbers.</p>
          {categories.RLSH.map(item => <CateringItem item={item} key={item.id} />)}
        </Section>
        <Section>
          <h2 className='title is-size-4-mobile'>Salads</h2>
          <p>Choose from any of our delicious salads</p>
          {categories.SALD.map(item => <CateringItem item={item} key={item.id} />)}
        </Section>
        <BottomLinks />
      </CateringPage>
    )
  }
}

export default withDependencies(CateringSaladsPage, ['catering'], { includeProps: true })
