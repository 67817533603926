import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync } from '@fortawesome/free-solid-svg-icons'

import { Hero, Container } from '../containers'

class LoadingScreen extends React.Component {
  render () {
    return (
      <Hero classes='is-fullheight is-mobile'>
        <Container classes='has-text-centered loading-screen__text'>
          <FontAwesomeIcon icon={faSync} spin size='10x' />
        </Container>
      </Hero>
    )
  }
}

export default LoadingScreen
