import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import classNames from 'classnames'

import { BlippedButton } from '.'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'

class CateringNavbar extends React.Component {
  constructor () {
    super()

    this.state = {
      expanded: false
    }

    this.handleToggleExpanded = () => this.setState(state => ({ ...state, expanded: !state.expanded }))
  }

  render () {
    const { expanded } = this.state

    return (
      <nav className='navbar catering-navbar' role='navigation' aria-label='main navigation'>
        <div className='navbar-brand'>
          {/* eslint-disable-next-line */}
          <a href='https://www.andalousgrill.com' className='navbar-item'>
            <img className='catering-navbar__image' src='https://andalous-website.s3.us-east-2.amazonaws.com/image-uploads-manual/andalous-logo-tagged-regged-194w.png' alt='' />
          </a>

          {/* eslint-disable-next-line */}
          <a role='button' className={classNames('navbar-burger burger catering-navbar__burger', {
            'is-active': expanded
          })} onClick={this.handleToggleExpanded} aria-label='menu' aria-expanded='false'
          >
            <span aria-hidden='true' />
            <span aria-hidden='true' />
            <span aria-hidden='true' />
            {(this.props.items.length > 0) && (<div className='catering-navbar__burger-blip'>{this.props.items.length}</div>)}
          </a>
        </div>

        <div className={classNames('navbar-menu', {
          'is-active': expanded
        })}
        >
          <div className='navbar-start'>
            <Link to='/catering' className='navbar-item'>Catering Home</Link>
            <Link to='/catering/lunchbox' className='navbar-item'>Lunch Boxes</Link>
            <Link to='/catering/appetizers' className='navbar-item'>Appetizers</Link>
            <Link to='/catering/salads' className='navbar-item'>Dips & Salads</Link>
            <Link to='/catering/veggies' className='navbar-item'>Veggies & Rice</Link>
            <Link to='/catering/grill' className='navbar-item'>Meat Specialties</Link>
            <Link to='/catering/desserts' className='navbar-item'>Desserts & Staff</Link>
          </div>

          <div className='navbar-end'>
            <div className='catering-navbar__end-container'>
              <BlippedButton icon={faShoppingCart} innerClasses='is-primary' title='View Cart' onClick={() => this.props.history.push('/catering/cart')} blip={this.props.items.length} />
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default connect(
  state => ({
    items: state.cateringOrder.items
  })
)(withRouter(CateringNavbar))
