import config from '../config'

const initialState = {
  name: '',
  location: null,
  email: '',
  phone: '',
  items: [],
  checkout: {
    active: false,
    data: {},
    time: null
  },
  tip: 0,
  tipFormat: '',
  specialRequest: ''
}

const orderReducer = (state = initialState, action) => {
  const type = action.type // eslint-disable-line no-unused-vars

  if (type === 'order.name') return { ...state, name: action.name }
  if (type === 'order.location') return updateCart({ ...state, location: action.location })
  if (type === 'order.email') return { ...state, email: action.email }
  if (type === 'order.phone') return { ...state, phone: action.phone }
  if (type === 'order.tip') return updateCart({ ...state, tip: Number(parseFloat(action.tip).toFixed(2)) })
  if (type === 'order.tipFormat') return updateCart({ ...state, tipFormat: action.tipFormat })
  if (type === 'order.addItem') {
    const items = state.items.slice(0)
    const newItem = action.item

    if (state.derived.totalItems >= config.maxTotalItems) return state

    const itemIndices = items
      .map((item, index) => [index, item.id, item.type])
      .filter(([index, id, type]) => id === newItem.id && type === 'item')
      .map(([index]) => index)
    const itemIndex = itemIndices.length ? itemIndices[0] : -1

    if (newItem.type === 'item' && ~itemIndex) {
      const oldItem = items[itemIndex]
      if (oldItem.quantity >= config.maxSingleItems) return state

      items[itemIndex] = {
        ...oldItem,
        quantity: oldItem.quantity + 1
      }
    } else {
      items.push({
        ...newItem,
        quantity: 1
      })
    }

    return updateCart(state, items)
  }
  if (type === 'order.increaseQuantity') {
    const items = state.items.slice(0)
    const item = items[action.index]

    if (!item) return state
    if (item.quantity >= config.maxSingleItems) return state
    if (state.derived.totalItems >= config.maxTotalItems) return state

    items[action.index] = { ...item, quantity: item.quantity + 1 }
    return updateCart(state, items)
  }
  if (type === 'order.decreaseQuantity') {
    const items = state.items.slice(0)
    const item = items[action.index]

    if (!item) return state

    if (item.quantity - 1 <= 0) {
      return updateCart({ ...state, items: items.filter(existing => existing !== item) })
    }

    items[action.index] = { ...item, quantity: item.quantity - 1 }
    return updateCart(state, items)
  }
  if (type === 'order.setQuantity') {
    const items = state.items.slice(0)
    const item = items[action.index]

    if (!item) return state

    const quantityLeft = config.maxTotalItems - state.derived.totalItems + item.quantity
    const quantity = Math.min(action.quantity, config.maxSingleItems, quantityLeft)

    if (quantity < 0) {
      return updateCart({ ...state, items: items.filter(existing => existing !== item) })
    }

    items[action.index] = { ...item, quantity }
    return updateCart(state, items)
  }
  if (type === 'order.clear') {
    return updateCart({ ...state, items: [], tip: 0, tipFormat: '', specialRequest: '' })
  }

  if (type === 'order.checkout') {
    return { ...state, checkout: { active: true, data: action.data, time: new Date().toISOString() } }
  }

  if (type === 'order.exitCheckout') {
    return { ...state, checkout: { active: false, data: {}, time: null } }
  }

  if (type === 'order.setSpecialRequest') {
    return { ...state, specialRequest: action.specialRequest }
  }

  return updateCart(state)
}

const updateCart = (state, items = null) => {
  if (!items) items = state.items

  const derived = {}

  derived.subtotal = items.map(item => item.price * item.quantity).reduce((a, b) => a + b, 0)
  derived.tip = state.tipFormat === 'custom' ? state.tip : derived.subtotal / 100 * Number(state.tipFormat)
  derived.tax = state.location
    ? derived.subtotal * state.location.taxRate / 100
    : 0
  derived.total = derived.subtotal + derived.tax + (isNaN(derived.tip) ? 0 : derived.tip)
  derived.totalItems = items.map(item => item.quantity).reduce((a, b) => a + b, 0)

  const anyPlusEnabled = derived.totalItems < config.maxTotalItems
  derived.anyPlusEnabled = anyPlusEnabled

  const updatedItems = items.map(item => ({ ...item, plusEnabled: anyPlusEnabled && item.quantity < config.maxSingleItems }))

  return { ...state, items: updatedItems, derived, tip: derived.tip }
}

export default orderReducer
