import React from 'react'

import { Level } from '../containers'

class DebugModal extends React.Component {
  render () {
    return (
      <div className='debug-modal'>
        <Level
          left={
            <h1 className='title'>Debug Output</h1>
          } right={
            <button className='button' onClick={this.props.quit}>Close</button> // eslint-disable-line
          }
        >&nbsp;
        </Level>
        <pre>
          <code>
            {JSON.stringify(this.props.data.output, null, 2)}
          </code>
        </pre>
      </div>
    )
  }
}

export default DebugModal
