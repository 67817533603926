import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { faCartPlus } from '@fortawesome/free-solid-svg-icons'

import createAction from '../actions'

import { Level } from '../containers'
import { BlippedButton } from '.'

class MenuModal extends React.Component {
  constructor () {
    super()

    this.handleAdd = () => {
      const item = this.props.data.item
      if (!item) return

      if (item.type === 'item') this.props.addToCart(item)
      if (item.type === 'platter') {
        this.props.setPlatter(item)
        this.props.history.push('/platter')
      }
      if (item.type === 'container') {
        this.props.addToCart(item.items[0])
      }

      this.props.quit()
    }

    this.handleContainerAdd = (subItem) => () => {
      this.props.addToCart(subItem)
      this.props.quit()
    }
  }

  render () {
    const { item } = this.props.data
    const { name, price, id, type } = item

    const cartItems = this.props.cart.filter(item => item.type === type && item.id === id)
    const cartItem = cartItems[0]
    const blip = cartItems.map(item => item.quantity).reduce((a, b) => a + b, 0)
    const disabled = (cartItem ? !cartItem.plusEnabled : !this.props.anyPlusEnabled) || this.props.checkoutActive

    return (
      <div className='menu-modal'>
        <img className='menu-modal__image' src={item.image} alt={item.name} onClick={this.handleAdd} />
        <h1 className='menu-modal__title'>{name}</h1>
        <div className='menu-modal__description'>{item.description}</div>
        {
          type === 'container' ? (
            <div className='menu-modal__container-selections'>
              {
                item.items.map(subItem => {
                  const subBlip = this.props.cart
                    .filter(item => item.type === 'item' && item.id === subItem.id)
                    .map(item => item.quantity)
                    .reduce((a, b) => a + b, 0)

                  const name = subItem.name.startsWith(item.name) ? subItem.name.slice(item.name.length) : subItem.name

                  return (
                    <div className='menu-modal__container-selection' key={subItem.id}>
                      <div className='menu-modal__container-selection-title'>
                        {name}
                      </div>
                      <div className='menu-modal__container-selection-price'>
                        ${Number(subItem.price).toFixed(2)}
                      </div>
                      <div className='menu-modal__container-selection-button'>
                        <BlippedButton icon={faCartPlus} innerClasses='is-dark' title='Add' blip={subBlip} disabled={disabled} onClick={this.handleContainerAdd(subItem)} />
                      </div>
                    </div>
                  )
                })
              }
            </div>
          ) : (
            <div className='menu-modal__price'>${Number(price).toFixed(2)}</div>
          )
        }
        <Level
          classes='menu-modal__level is-mobile' left={
            <button className='button' onClick={this.props.quit}>Close</button> // eslint-disable-line
          } right={
            type === 'container'
              ? undefined
              : <BlippedButton icon={faCartPlus} innerClasses='is-dark' title='Add' blip={blip} disabled={disabled} onClick={this.handleAdd} />
          }
        />
      </div>
    )
  }
}

export default connect(
  state => ({
    cart: state.order.items,
    checkoutActive: state.order.checkout.active,
    anyPlusEnabled: state.order.derived.anyPlusEnabled
  }),
  dispatch => ({
    addToCart: (item) => dispatch(createAction('order.addItem', { item })),
    setPlatter: (platter) => dispatch(createAction('platter.set', { platter }))
  })
)(withRouter(MenuModal))
