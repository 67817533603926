import React, { useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'
import { detect } from 'detect-browser'

import { Hero } from '.'
import { TitleBar } from '../components'

const browser = detect()

const OrderLayout = (props) => {
  const mainColumnRef = useRef()
  const orderColumnRef = useRef()

  useEffect(() => {
    let looping = browser.os === 'iOS'

    const loop = () => {
      if (!looping) return false

      const height = `${window.innerHeight}px`

      if (mainColumnRef.current) {
        mainColumnRef.current.style.minHeight = height
        mainColumnRef.current.style.maxHeight = height
        mainColumnRef.current.style.height = height
      }

      if (orderColumnRef.current) {
        orderColumnRef.current.style.minHeight = height
        orderColumnRef.current.style.maxHeight = height
        orderColumnRef.current.style.height = height
      }

      window.requestAnimationFrame(loop)
    }

    loop()

    return () => (looping = false)
  })

  return (
    <Hero classes='is-fullheight' innerClasses='order-layout__hero-body'>
      <Helmet>
        <title>Online Ordering | Andalous Mediterranean Grill</title>
        <meta property='og:title' content='Andalous Mediterranean Grill Online Ordering' />
        <meta property='og:type' content='article' />
        <meta property='og:description' content='Andalous Mediterranean Grill offers a delicious variety of dips, salads, veggies, protiens and desserts. Use our online ordering system for fast to-go orders.' />
        <meta property='og:url' content='https://order.andalousgrill.com/' />
        <meta property='og:image' content='https://andalous-website.s3.us-east-2.amazonaws.com/image-uploads-manual/Andalous-OG-Online-Orders.png' />
      </Helmet>
      <div className='columns is-gapless order-layout__columns'>
        <div className='column order-layout__main-column' ref={mainColumnRef}>
          <TitleBar hasCart blip={props.blip}>
            {props.title}
          </TitleBar>
          {
            props.titleSection ? (
              <div className='order-layout__title-section'>
                {props.titleSection}
              </div>
            ) : undefined
          }
          <div className='order-layout__main-content'>
            {props.children}
          </div>
        </div>
        <div className='column order-layout__order-column' ref={orderColumnRef} style={{ maxWidth: window.innerWidth < 440 ? window.innerWidth : 'auto' }}>
          {props.order}
        </div>
      </div>
    </Hero>
  )
}

export default OrderLayout
