// a simple component that displays
//   - the 'cards' prop (array of react elements) as a responsive set of cards if provided
//   - the 'fallback' prop (react element) if the cards array is empty or missing

import React from 'react'
import classNames from 'classnames'

class IconCards extends React.Component {
  render () {
    const cards = this.props.cards || []

    return (
      <div className={classNames('columns is-multiline is-mobile', this.props.classes)} key={this.props.notKey}>
        {cards.length
          ? cards.map((card, index) => (
            <div className='column is-half-mobile is-one-third-tablet is-one-quarter-desktop is-one-fifth-widescreen' key={index}>
              {card}
            </div>
          ))
          : this.props.fallback}
      </div>
    )
  }
}

export default IconCards
