import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

class BlippedButton extends React.Component {
  render () {
    return (
      <div className={classNames('blipped-button__container', this.props.classes)}>
        <button className={classNames('blipped-button__button button', this.props.innerClasses)} onClick={this.props.onClick} disabled={this.props.disabled}>
          {this.props.title ? (
            <>
              <span>{this.props.title}</span>
              &nbsp;
            </>
          ) : undefined}
          <FontAwesomeIcon icon={this.props.icon} className='icon blipped-button__icon' />
        </button>
        {
          this.props.blip ? (
            <div className='blipped-button__blip'>
              {this.props.blip}
            </div>
          ) : undefined
        }
      </div>
    )
  }
}

export default BlippedButton
