import React from 'react'
import { connect } from 'react-redux'

import { LoadingScreen } from '../components'
import { loadMenu, loadLocations, loadCatering } from '../actions/loaders'

const alreadyLoaded = []

const withDependencies = (Component, dependencies, options = {}) => {
  function WithDependencies (props) {
    const loadDependency = props.withDependencies__loadDependency
    dependencies.forEach(dependency => loadDependency(dependency))

    if (!props.withDependencies__loaded) return <LoadingScreen />

    const extraProps = options.includeProps ? props.withDependencies__values : {}

    return <Component {...props} {...extraProps} />
  }

  const includesAll = (source, list) => !list.filter(element => !source.includes(element)).length

  return connect(
    state => {
      const loadedDependencies = {}
      dependencies.forEach(dependency => (loadedDependencies[dependency] = state.loaders.values[dependency]))

      return {
        withDependencies__values: loadedDependencies,
        withDependencies__loaded: includesAll(state.loaders.loaded, dependencies)
      }
    },
    dispatch => ({
      withDependencies__loadDependency: (name) => {
        const lookup = {
          menu: loadMenu,
          locations: loadLocations,
          catering: loadCatering
        }

        const loader = lookup[name]
        if (!loader) return

        if (alreadyLoaded.includes(name)) return
        alreadyLoaded.push(name)

        dispatch(loader())
      }
    })
  )(WithDependencies)
}

export default withDependencies
