import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'

import createAction from '../actions'

import { MenuModal, DebugModal, OrderModal } from '../components'

class ModalContainer extends React.Component {
  constructor () {
    super()

    this.rootRef = React.createRef()
    this.onQuit = (event) => {
      if (event.target !== this.rootRef.current) return

      this.props.quit()
    }
  }

  render () {
    const { name, data, cancelable } = this.props

    const Modal = {
      menu: MenuModal,
      debug: DebugModal,
      order: OrderModal
    }[name]

    const open = Modal != null ? this.props.open : false

    // console.log('cancelable', cancelable)

    return (
      <div
        className={classNames('modal-container', {
          'modal-container--closed': !open
        })} ref={this.rootRef} onClick={cancelable ? this.onQuit : undefined}
      >
        {
          open ? (
            <div className='modal-container__modal'>
              {Modal && <Modal data={data} quit={this.props.quit} />}
            </div>
          ) : undefined
        }
      </div>
    )
  }
}

export default connect(
  state => ({
    open: state.modal.open,
    name: state.modal.name,
    data: state.modal.data,
    cancelable: state.modal.cancelable
  }),
  dispatch => ({
    quit: () => dispatch(createAction('modal.close'))
  })
)(ModalContainer)
