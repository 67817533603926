import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ErrorMessage } from 'formik'
import Recaptcha from 'react-recaptcha'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { DateUtils } from 'react-day-picker'
import { parseISO } from 'date-fns'
import formatDate from 'date-fns/format'
import { enUS } from 'date-fns/locale'
import { MultiSelect } from 'react-sm-select'

import cateringFormWrapper from '../containers/cateringFormWrapper'
import FormStatus from '../components/FormStatus'
import FormRequired from '../components/FormRequired'
import FormToState from '../components/FormToState'

class cateringForm extends Component {
  constructor (props) {
    super(props)
    this.recaptchaInstance = ''
  }

  componentDidMount () {
    const script = document.createElement('script')
    script.src = 'https://www.google.com/recaptcha/api.js'
    script.async = true
    script.defer = true
    document.body.appendChild(script)

    const datePickerInput = document.querySelector('.DayPickerInput input')
    datePickerInput.classList.add('input')

    if (this.props.transferred.location) {
      this.props.setFieldValue(
        'location',
        `${this.props.transferred.location.id}`
      )
    }
    if (this.props.transferred.fulfillment) this.props.setFieldValue('fulfillment', this.props.transferred.fulfillment)

    this.props.setFieldValue('items', this.prepareOrder())
    this.props.setFieldTouched('items')
    this.props.setFieldValue('tip', this.props.transferred.tip)
    this.props.setFieldTouched('tip')

    const isDeliverySet = this.props.transferred.items.findIndex(el => el.code === 'XCHG_DLVY') > -1
    if (isDeliverySet) {
      this.props.setFieldValue('fulfillment', 'Delivery')
      this.props.setFieldTouched('fulfillment')
    }
    const backFillValues = ['fulfillment', 'date', 'time', 'firstname', 'lastname', 'email', 'phone', 'company', 'address1', 'address2', 'city', 'zip', 'comment']
    backFillValues.forEach((val, index) => {
      if (this.props.values[val] !== this.props.transferred[val]) {
        this.props.setFieldValue(val, this.props.transferred[val])
        this.props.setFieldTouched(val)
      }
    })
  }

  componentDidUpdate () {
    if (!this.props.status.initial) this.recaptchaInstance.reset()
    if (this.props.status.success && (this.props.transferred.items.length > 0)) {
      this.props.transferred.clearOrder()
      this.props.transferred.updateTip(0)
      this.props.transferred.updateTipFormat('')
      this.props.transferred.updateLocation(null)
    }
  }

  handleTimeChange = ev => {
    const timePart = ev.target.id
    const timeValue = ev.target.value
    let currentHour = this.props.values.time.split(':')[0]
    let currentMinute = this.props.values.time.split(':')[1].split(' ')[0]
    let currentPeriod = this.props.values.time.split(':')[1].split(' ')[1]
    if (timePart === 'timeHour') currentHour = timeValue
    if (timePart === 'timeMinute') currentMinute = timeValue
    if (timePart === 'timePeriod') currentPeriod = timeValue
    const newTime = [[currentHour, currentMinute].join(':'), currentPeriod].join(' ')
    this.props.setFieldValue('time', newTime)
  }

  parseInputDate = (str) => {
    const parsed = parseISO(str)
    if (DateUtils.isDate(parsed)) return parsed
    return undefined
  }

  formatInputDate = (date, format) => {
    return formatDate(date, format, { locale: enUS })
  }

  prepareOrder = () => {
    const order = this.props.transferred.items.map(item => {
      const { id, type, category, code, tier, quantity, items } = item

      const tierString = `tier${tier}`

      if (item.type === 'item') return { id, type, category, code, tier: tierString, quantity }
      if (item.type === 'lunchbox') {
        return {
          id,
          type,
          category,
          code,
          quantity,
          items: Object.keys(items).map(selectionKind => [items[selectionKind].id, items[selectionKind].category, items[selectionKind].code])
        }
      }

      return null
    }).filter(value => value !== null)

    return order
  }

  formatPhoneValue = (val) => {
    if (val.length < 10) return val
    const eliminationRegExp = /[\s()-]/gi
    const cleansedVal = val.replace(eliminationRegExp, '')
    const formattedVal = ['(', cleansedVal.slice(0, 3), ')', ' ', cleansedVal.slice(3, 6), '-', cleansedVal.slice(6)].join('')
    return formattedVal
  }

  render () {
    const locationOptions = this.props.transferred.locations.map((location, index) => { return { value: `${location.id}`, label: location.name } })
    const fulfillmentOptions = [{ value: 'Pickup', label: 'Pickup' }, { value: 'Delivery', label: 'Delivery' }]

    const {
      values,
      handleSubmit,
      handleBlur,
      handleChange,
      isValid,
      isSubmitting,
      status,
      errors,
      touched,
      setFieldValue,
      setFieldTouched
    } = this.props

    const hours = Array.from(Array(12).keys(), x => x + 1).map(el => <option key={el} value={el}>{el}</option>)
    const minutes = ['00', '15', '30', '45'].map(el => <option key={el} value={el}>{el}</option>)
    const periods = ['AM', 'PM'].map(el => <option key={el} value={el}>{el}</option>)

    const recaptchaSiteKey = '6LetSLMUAAAAAF9-JEvkpo-XLi02zjn9B9eNQbaO'

    return (
      <>
        <form onSubmit={handleSubmit}>
          <FormToState formValues={values} />
          <div className='field'>
            <div className={`control${errors.location && touched.location ? ' is-danger' : ''}`}>
              <MultiSelect
                mode='single'
                value={[`${values.location}`]}
                onChange={value => {
                  const locationIndex = this.props.transferred.locations.findIndex(location => location.id === Number(value[0]))
                  this.props.transferred.updateLocation(this.props.transferred.locations[locationIndex])
                  setFieldValue('location', value[0])
                }}
                onBlur={() => setFieldTouched('location', true)}
                options={locationOptions}
                id='location'
                valuePlaceholder='Please select location'
                className='input'
              />
            </div>
            <ErrorMessage name='location' render={msg => <p className='help is-danger'>{msg}</p>} />
          </div>

          <div className='field'>
            <div className={`control${errors.fulfillment && touched.fulfillment ? ' is-danger' : ''}`}>
              <MultiSelect
                mode='single'
                value={[values.fulfillment]}
                onChange={value => {
                  this.props.transferred.removeItem(this.props.transferred.deliveryItem.id)
                  if (value[0] === 'Delivery') {
                    this.props.transferred.addToCart(this.props.transferred.deliveryItem)
                  }
                  setFieldValue('fulfillment', value[0])
                }}
                onBlur={() => setFieldTouched('fulfillment', true)}
                options={fulfillmentOptions}
                id='fulfillment'
                valuePlaceholder='Is this order for pickup or delivery?'
              />
            </div>
            <ErrorMessage name='fulfillment' render={msg => <p className='help is-danger'>{msg}</p>} />
            <p className='is-size-7'>$35 Delivery charge, 10 mile radius,<br />additional charges may apply for long-distance deliveries</p>
          </div>

          <div className='field'>
            <div className={`control translated-day-picker${errors.date && touched.date ? ' date-danger' : ''}`}>
              <DayPickerInput
                placeholder='Date of pickup/delivery'
                onDayChange={day => setFieldValue('date', day)}
                dayPickerProps={{
                  fromMonth: new Date(),
                  disabledDays: {
                    before: new Date()
                  },
                  selectedDays: values.date ? new Date(values.date) : new Date()
                }}
                inputProps={{
                  name: 'date',
                  onBlur: handleBlur,
                  type: 'text',
                  id: 'date',
                  readOnly: true
                }}
                value={values.date ? new Date(values.date) : new Date()}
                formatDate={this.formatInputDate}
                format='MM/dd/yy'
                parseDate={this.parseInputDate}
                keepFocus={false}
              />
            </div>
            <ErrorMessage name='date' render={msg => <p className='help is-danger'>{msg}</p>} />
          </div>

          <div className='field has-addons'>
            <div className={`control translated-day-picker${errors.time && touched.time ? ' time-danger' : ''}`}>
              <div className='select'>
                <select name='hour-select' id='timeHour' onChange={ev => this.handleTimeChange(ev)} value={values.time.split(':')[0]}>
                  {hours}
                </select>
              </div>
            </div>
            <div className={`control translated-day-picker${errors.time && touched.time ? ' time-danger' : ''}`}>
              <div className='select'>
                <select name='minute-select' id='timeMinute' onChange={ev => this.handleTimeChange(ev)} value={values.time.split(':')[1].split(' ')[0]}>
                  {minutes}
                </select>
              </div>
            </div>
            <div className={`control translated-day-picker${errors.time && touched.time ? ' time-danger' : ''}`}>
              <div className='select'>
                <select name='period-select' id='timePeriod' onChange={ev => this.handleTimeChange(ev)} value={values.time.split(' ')[1]}>
                  {periods}
                </select>
              </div>
            </div>
            <ErrorMessage name='time' render={msg => <p className='help is-danger'>{msg}</p>} />
          </div>

          {this.props.children}

          <div className='field'>
            <div className='control'>
              <input
                type='text'
                placeholder='First Name'
                name='firstname'
                id='firstname'
                value={values.firstname}
                onBlur={handleBlur}
                onChange={handleChange}
                className={`input${errors.firstname && touched.firstname ? ' is-danger' : ''}`}
              />
            </div>
            <ErrorMessage name='firstname' render={msg => <p className='help is-danger'>{msg}</p>} />
          </div>

          <div className='field'>
            <div className='control'>
              <input
                type='text'
                placeholder='Last Name'
                name='lastname'
                id='lastname'
                value={values.lastname}
                onBlur={handleBlur}
                onChange={handleChange}
                className={`input${errors.lastname && touched.lastname ? ' is-danger' : ''}`}
              />
            </div>
            <ErrorMessage name='lastname' render={msg => <p className='help is-danger'>{msg}</p>} />
          </div>

          <div className='field'>
            <div className='control'>
              <input
                type='email'
                placeholder='E-mail address'
                name='email'
                id='email'
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
                className={`input${errors.email && touched.email ? ' is-danger' : ''}`}
              />
            </div>
            <ErrorMessage name='email' render={msg => <p className='help is-danger'>{msg}</p>} />
          </div>

          <div className='field'>
            <div className='control'>
              <input
                type='tel'
                placeholder='Primary phone number'
                name='phone'
                id='phone'
                value={values.phone}
                onBlur={handleBlur}
                onChange={e => {
                  const newValue = this.formatPhoneValue(e.target.value)
                  setFieldValue('phone', newValue)
                }}
                className={`input${errors.phone && touched.phone ? ' is-danger' : ''}`}
              />
            </div>
            <ErrorMessage name='phone' render={msg => <p className='help is-danger'>{msg}</p>} />
          </div>

          <div className='field'>
            <div className='control'>
              <input
                type='text'
                placeholder='Company name'
                name='company'
                id='company'
                value={values.company}
                onBlur={handleBlur}
                onChange={handleChange}
                className={`input${errors.company && touched.company ? ' is-danger' : ''}`}
              />
            </div>
            <ErrorMessage name='company' render={msg => <p className='help is-danger'>{msg}</p>} />
          </div>

          <div className='field'>
            <div className='control'>
              <input
                type='text'
                placeholder='Delivery address (line 1)'
                name='address1'
                id='address1'
                value={values.address1}
                onBlur={handleBlur}
                onChange={handleChange}
                className={`input${errors.address1 && touched.address1 ? ' is-danger' : ''}`}
              />
            </div>
            <ErrorMessage name='address1' render={msg => <p className='help is-danger'>{msg}</p>} />
          </div>

          <div className='field'>
            <div className='control'>
              <input
                type='text'
                placeholder='Delivery address (line 2)'
                name='address2'
                id='address2'
                value={values.address2}
                onBlur={handleBlur}
                onChange={handleChange}
                className={`input${errors.address2 && touched.address2 ? ' is-danger' : ''}`}
              />
            </div>
            <ErrorMessage name='address2' render={msg => <p className='help is-danger'>{msg}</p>} />
          </div>

          <div className='field'>
            <div className='control'>
              <input
                type='text'
                placeholder='Delivery City'
                name='city'
                id='city'
                value={values.city}
                onBlur={handleBlur}
                onChange={handleChange}
                className={`input${errors.city && touched.city ? ' is-danger' : ''}`}
              />
            </div>
            <ErrorMessage name='city' render={msg => <p className='help is-danger'>{msg}</p>} />
          </div>

          <div className='field'>
            <div className='control'>
              <input
                type='text'
                placeholder='Delivery ZIP'
                name='zip'
                id='zip'
                value={values.zip}
                onBlur={handleBlur}
                onChange={handleChange}
                className={`input${errors.zip && touched.zip ? ' is-danger' : ''}`}
              />
            </div>
            <ErrorMessage name='zip' render={msg => <p className='help is-danger'>{msg}</p>} />
          </div>

          <div className='field'>
            <div className='control'>
              <textarea
                placeholder='Comments, questions or concerns'
                name='comment'
                value={values.comment}
                onBlur={handleBlur}
                onChange={handleChange}
                className={`textarea${errors.comment && touched.comment ? ' is-danger' : ''}`}
              />
            </div>
            <ErrorMessage name='comment' render={msg => <p className='help is-danger'>{msg}</p>} />
          </div>

          <div className='field'>
            <div className='control'>
              <Recaptcha
                sitekey={recaptchaSiteKey}
                render='explicit'
                ref={e => { this.recaptchaInstance = e }}
                verifyCallback={response => {
                  setFieldValue('recaptcha', response)
                }}
                onloadCallback={() => setFieldValue('recaptcha', '')}
              />
            </div>
            <ErrorMessage name='recaptcha' render={msg => <p className='help is-danger'>{msg}</p>} />
          </div>

          <div className='field'>
            <p>We will contact you to confirm your order and personally handle any questions or concerns you may have. Thank you for choosing Andalous Mediterranean Grill for your catering and special event needs.</p>
          </div>

          <div className='has-medium-bottom-margin'>
            <FormRequired messages={errors} visible={Object.keys(errors).length > 0} />
          </div>

          <div className='field'>
            <div className='control has-text-centered'>
              <button
                type='submit'
                className={`button is-primary is-medium${isSubmitting ? ' is-loading' : ''}`}
                onClick={() => {
                  this.props.setFieldValue('items', this.prepareOrder())
                  this.props.setFieldValue('tip', this.props.transferred.tip)
                }}
                disabled={!isValid || isSubmitting}
              >
                <span className='icon'>
                  <FontAwesomeIcon icon='paper-plane' />
                </span>
                <span>Submit</span>
              </button>
            </div>
          </div>

          <FormStatus status={status} />

        </form>
      </>
    )
  }
}

export default cateringFormWrapper(cateringForm)
