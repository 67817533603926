import React from 'react'

import { CateringPage } from '../containers'

class TermsOfService extends React.Component {
  render () {
    return (
      <CateringPage innerContainerClasses='content'>
        <div className='columns'>
          <div className='column is-full'>
            <h1 className='title is-size-2 is-size-3-mobile'>Andalous Grill Terms of Service</h1>

            <section className='section'>
              <h2 className='subtitle is-size-4-mobile'>Acceptance of Terms of Service</h2>
              <p>These Terms of Service govern your use of this website and are set forth by Andalous Mediterranean Grill Restaurants ("Andalous Grill"). This website uses a mobile-responsive design which allows it to optimize its functionality for desktop computers, tablets and mobile phones that can access the internet through popular browsers, therefore, this Terms of Service applies to this website regardless of the device by which it is accessed.</p>
            </section>

            <section className='section'>
              <h2 className='subtitle is-size-4-mobile'>Consent</h2>
              <p>Your use of this website constitutes your acceptance and agreement to our Terms of Service and Privacy Policy.</p>
            </section>

            <section className='section'>
              <h2 className='subtitle is-size-4-mobile'>Privacy and Personal Information</h2>
              <p>Andalous Grill protects the privacy of the personal information you provide us on this website. Any information submitted on this website is subject to our Privacy Policy.</p>
            </section>

            <section className='section'>
              <h2 className='subtitle is-size-4-mobile'>Website Use and Access</h2>
              <p>
                Andalous Grill grants you permission to use this website as set forth in these Terms of Service, provided that:
                <ul>
                  <li>The use of this website is soley for your personal use.</li>
                  <li>You will not copy or distribute any part of this website including copy, images, photographs, logos or any other assets.</li>
                  <li>You agree to not use this website for any malicious purposes including launching any automated system in a manner that would overwhelm the website with requests.</li>
                  <li>You agree that by using this website to place an online or catering order, and by submitting payment information you are an authorized user of the payment account.</li>
                  <li>You wll comply with these Terms of Service.</li>
                </ul>
              </p>
            </section>

            <section className='section'>
              <h2 className='subtitle is-size-4-mobile'>Intellectual Property Rights</h2>
              <p>All content on this website, including but not limited to  text, software, scripts, graphics, photos, sounds, music, videos, interactive features, trademarks, service marks and logos contained therein are owned by or licensed to Andalous Grill, unless otherwise noted, and are subject to copyright and other intellectual property rights under United States.</p>
            </section>

            <section className='section'>
              <h2 className='subtitle is-size-4-mobile'>Warranty Disclaimer</h2>
              <p>YOU AGREE THAT YOUR USE OF THE ANDALOUS GRILL WEBSITE SHALL BE AT YOUR SOLE RISK. YOU AGREE, TO THE EXTENT PERMITTED BY LAW, THAT ANDALOUS GRILL, IT'S OFFICERS, DIRECTORS, MANAGERS, EMPLOYEES OR AGENTS DISCLAIM ALL WARRANTIES EXPRESSED OR IMPLIED IN CONNECTION WITH YOUR USE OF THIS WEBSITE. ANDALOUS GRILL MAKES NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THIS SITE'S CONTENT OR FUNCTIONALITY. IF YOU CONSENT TO USE THIS WEBSITE, YOU AGREE TO USE THE WEBSITE AS IS.</p>
            </section>

            <section className='section'>
              <h2 className='subtitle is-size-4-mobile'>Limitation of Liability</h2>
              <p>IN NO EVENT SHALL ANDALOUS GRILL, ITS OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS, BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES WHATSOEVER RESULTING FROM ANY
                <ul>
                  <li>ERRORS, MISTAKES OR INACCURACIES OF THE CONTENT CONTAINED HEREIN.</li>
                  <li>ERRORS, ISSUES OR FAILURES ARISING FROM THE ACCESS OR USE OF THIS WEBSITE INCLUDING, BUT NOT LIMITED TO, ONLINE ORDERING, CREDIT CARD PROCESSING AND ORDER DELIVERY. </li>
                  <li>UNAUTHORIZED ACCESS OR USE OF OUR SERVERS.</li>
                  <li>UNAUTHORIZED ACCESS OR USE OF PERSONAL OR FINANCIAL INFORMATION. </li>
                  <li>FAILURE OR INTERRUPTION IN THE TRANSMISSION TO OR FROM OUR WEBSITE.</li>
                  <li>BUGS, VIRUSES TORJAN HORSES OR OTHER MALICIOUS SOFTWARE THAT MAY BE TRANSMITTED TO OR THROUGH OUR WEBSITE BY ANY THIRD-PARTY.</li>
                  <li>PERSONAL INJURY OR PROPERTY DAMANGE.</li>
                </ul>
              </p>
            </section>

          </div>
        </div>
      </CateringPage>
    )
  }
}

export default TermsOfService
