import React, { useCallback, useEffect, useState } from 'react'
import { AcceptHosted } from 'react-authorize-net'
import querystring from 'querystring'
import useFetch from 'use-http'
import { RadioGroup, RadioButton } from 'react-radio-buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import * as yup from 'yup'

import { useModal } from '../components'

import config from '../config'

const OrderModal = ({ data, quit }) => {
  const { order } = data
  const paymentButtonCallback = useCallback(button => {
    if (button == null) return

    button.click()
  }, [])

  const [frameSize, setFrameSize] = useState({ width: null, height: null })

  const [paymentMethod, setPaymentMethod] = useState('card')
  const [isPaid, setPaid] = useState(false)
  const [paymentResult, setPaymentResult] = useState('none')
  const [request] = useFetch(config.apiUrl)

  const [email, setEmail] = useState('')
  const [emailOptout, setEmailOptout] = useState(false)
  const [emailEntered, setEmailEntered] = useState(false)

  const emailReady = emailOptout || (email && yup.string().email().isValidSync(email))

  useEffect(() => {
    const handler = async (event) => {
      const message = event.data

      if (event.data.type !== 'authorizenet-update') return

      const data = querystring.parse(message.qstr)

      if (data.action === 'resizeWindow') {
        setFrameSize({
          width: data.width + 'px',
          height: data.height + 'px'
        })
      }

      if (data.action === 'transactResponse') {
        const response = JSON.parse(data.response)
        console.log(response)
        // if we need to check anything about the response, do it here

        setPaid(true)
        setPaymentResult('none')

        const verification = await request.post('/ordering/verify-payment', {
          orderToken: order.token,
          transactionId: response.transId, // DAMMIT WHY CAN'T YOU BE CONSISTENT FOR ONCE
          authorization: response.authorization,
          email: emailOptout ? '' : email
        })

        if (verification.ok) setPaymentResult('success')
        else setPaymentResult('error')
      }

      if (data.action === 'cancel') {
        setPaid(true)
        setPaymentResult('cancel')
      }
    }

    window.addEventListener('message', handler)
    return () => window.removeEventListener('message', handler)
  })

  const [modal, showModal] = useModal()

  const cancelOrder = async () => {
    const response = await showModal(
      'Cancel Order',
      'Are you sure you would like to cancel the order?',
      [{
        text: 'Yes',
        response: 'yes',
        className: 'is-danger'
      }, {
        text: 'No',
        response: 'no',
        isCancel: true
      }]
    )

    if (response !== 'yes') return

    setPaid(true)
    setPaymentResult('none')
    await request.post('/ordering/cancel-order', {
      orderToken: order.token
    })
    quit()
  }

  const deferOrder = async () => {
    setPaid(true)
    setPaymentResult('none')
    const result = await request.post('/ordering/defer-order', {
      orderToken: order.token,
      email: emailOptout ? '' : email
    })

    if (result && result.ok) setPaymentResult('success')
    else setPaymentResult('error')
  }

  // console.log('token', order.paymentFormToken)

  return (
    <div className='order-modal'>
      <h1 className='title'>Confirm your order</h1>

      <table className='table is-striped is-hoverable is-fullwidth'>
        <tbody>
          <tr>
            <td>Name:</td>
            <td>{order.config.name}</td>
          </tr>
          <tr>
            <td>Location:</td>
            <td>{order.config.location.name}</td>
          </tr>
        </tbody>
      </table>

      <h2 className='subtitle'>Ordered items:</h2>
      <div className='order-modal__items'>
        {
          order.config.items.map((item, index) => (
            <div className='order-modal__item level is-mobile' key={index}>
              <div className='order-modal__item-quantity level-left'>
              x{item.quantity}
              </div>
              <div className='order-modal__item-description level-right'>
                <div className='order-modal__item-name'>{item.name}</div>
                <div className='order-modal__item-contents'>
                  {
                    item.items ? (
                      <table className='table is-striped is-fullwidth is-hoverable is-narrow'>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Quantity</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            item.items
                              .map(subitem => subitem.selections.map(selection => selection.name))
                              .flat()
                              .reduce((acc, item) => {
                                const existing = acc.filter(i => i.name === item)[0]

                                if (existing) existing.quantity++
                                else acc.push({ name: item, quantity: 1 })

                                return acc
                              }, [])
                              .map(({ name, quantity }, index) => (
                                <tr key={index}>
                                  <td>{name}</td>
                                  <td>{quantity}</td>
                                </tr>
                              ))
                          }
                        </tbody>
                      </table>
                    ) : ''
                  }
                </div>
                <div className='order-modal__item-price'>${item.price.toFixed(2)}</div>
              </div>
            </div>
          ))
        }
      </div>

      <div className='level'>
        <div className='level-left' />
        <div className='level-right'>
          <table className='table is-striped is-hoverable'>
            <tbody>
              <tr>
                <td>Subtotal:</td>
                <td>${(order.config.subtotal || 0).toFixed(2)}</td>
              </tr>
              <tr>
                <td>Tax:</td>
                <td>${(order.config.tax || 0).toFixed(2)}</td>
              </tr>
              <tr>
                <td><strong>Total</strong></td>
                <td><strong>${(order.config.price || 0).toFixed(2)}</strong></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className='order-modal__email-input'>
        <div className='field'>
          <label className='label'>Email:</label>
          <div className='control'>
            <input
              className='input'
              type='email'
              placeholder='john.wick@example.com'
              disabled={emailOptout}
              value={email}
              onChange={event => setEmail(event.target.value)}
              onBlur={() => setEmailEntered(true)}
            />
          </div>
          <p className='help is-danger'>
            {
              (
                emailEntered &&
                !emailOptout &&
                (!yup.string().email().isValidSync(email) || !email)
              ) ? 'Error: invalid email' : undefined
            }
          </p>
        </div>
        <div className='field'>
          <label className='checkbox'>
            <input type='checkbox' onChange={() => setEmailOptout(!emailOptout)} />
            {' '}
            Do not send me an email to confirm the order
          </label>
        </div>
      </div>

      {
        isPaid ? (
          <div className='order-modal__thank-you-wrapper'>
            {
              (request.error || paymentResult === 'error') && (
                <div className='message is-error'>
                  <div className='message-header'>
                    <p>Error</p>
                  </div>
                  <div className='message-body'>
                    Order cannot be completed (server error)
                    <div className='has-text-centered'>
                      <button className='button' onClick={quit}>Quit</button>
                    </div>
                  </div>
                </div>
              )
            }
            {
              request.loading && (
                <div className='order-modal__thank-you-message'>
                  <div className='order-modal__thank-you-icon'>
                    <FontAwesomeIcon icon={faSync} size='3x' spin />
                  </div>
                </div>
              )
            }
            {
              (paymentResult !== 'success') ? undefined : (
                <div className='order-modal__thank-you-message has-text-success'>
                  <div className='order-modal__thank-you-icon'>
                    <FontAwesomeIcon icon={faCheckCircle} size='3x' />
                  </div>
                  <div className='order-modal__thank-you-text'>
                    Thank you for your order
                    <br />
                    <button className='button' onClick={quit}>Ok</button>
                  </div>
                </div>
              )
            }
            {
              (paymentResult !== 'cancel') ? undefined : (
                <div className='order-modal__thank-you-message has-text-danger'>
                  <div className='order-modal__thank-you-icon'>
                    <FontAwesomeIcon icon={faTimesCircle} size='3x' />
                  </div>
                  <div className='order-modal__thank-you-text'>
                    Payment cancelled
                    <br />
                    <button className='button' onClick={() => setPaid(false)}>Retry</button>
                    &nbsp;
                    <button className='button is-danger' onClick={cancelOrder}>Cancel order</button>
                  </div>
                </div>
              )
            }
          </div>
        ) : (
          !emailReady ? (
            <div className='order-modal__email-not-ready'>
              <div className=' message is-warning'>
                <h2 className='message-header'>Email Address Required</h2>
                <p className='message-body has-text-centered'>Please enter an email address before selecting a payment method</p>
              </div>
              <div className='order-modal__email-not-ready-cancel-button has-text-centered'>
                <button className='button is-danger is-small' onClick={cancelOrder}>Cancel order</button>
              </div>
            </div>
          ) : (
            <div className='order-modal__payment'>
              <h2 className='subtitle'>Select Payment Method</h2>
              <RadioGroup horizontal value={paymentMethod} onChange={value => setPaymentMethod(value)}>
                <RadioButton value='card' iconSize={20} iconInnerSize={10} rootColor='hsl(0, 0%, 71%)' pointColor='hsl(171, 100%, 41%)'>
                  Credit Card
                </RadioButton>
                <RadioButton value='defer' iconSize={20} iconInnerSize={10} rootColor='hsl(0, 0%, 71%)' pointColor='hsl(171, 100%, 41%)'>
                  At Pickup
                </RadioButton>
              </RadioGroup>
              <div className='order-modal__payment-option'>
                {
                  (paymentMethod !== 'card') ? undefined : (
                    <div
                      className='order-modal__payment-form' style={{
                      // width: frameSize.width,
                        width: window.innerWidth < 400 ? 300 : 400,
                        height: frameSize.height
                      }}
                    >
                      <AcceptHosted
                        formToken={order.paymentFormToken}
                        type='iframe'
                        mode='sandbox'
                        onResize={(...args) => console.log('stuff resized', args)}
                        onTransact={(response) => console.log('got transaction', response)}
                        onMessage={(message) => console.log('got message', message)}
                      >
                        <button className='button is-primary' ref={paymentButtonCallback}>
                          Pay now
                        </button>
                      </AcceptHosted>
                    </div>
                  )
                }
                {
                  (paymentMethod !== 'defer') ? undefined : (
                    <div className='order-modal__defer-message'>
                      <div className='order-modal__defer-text'>
                        You will be asked for a credit card or cash at the restaurant
                        <button className='button is-success is-large' onClick={deferOrder}>Continue</button>
                      </div>
                    </div>
                  )
                }
                <div className='has-text-centered'>
                  <button className='button is-danger is-small' onClick={cancelOrder}>Cancel order</button>
                </div>
              </div>
            </div>
          )
        )
      }
      {modal}
    </div>
  )
}

export default OrderModal
