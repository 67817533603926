const initialState = {
  location: null,
  items: [],
  tip: 0,
  tipFormat: '',
  fulfillment: '',
  date: '',
  time: '1:00 AM',
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  company: '',
  address1: '',
  address2: '',
  city: '',
  zip: '',
  comment: ''
}

const cateringOrderReducer = (state = initialState, action) => {
  const type = action.type

  if (type === 'cateringOrder.location') return updateCart({ ...state, location: action.location })
  if (type === 'cateringOrder.tip') return updateCart({ ...state, tip: Number(parseFloat(action.tip).toFixed(2)) })
  if (type === 'cateringOrder.tipFormat') return updateCart({ ...state, tipFormat: action.tipFormat })
  if (type === 'cateringOrder.fulfillment') return updateCart({ ...state, fulfillment: action.fulfillment })
  if (type === 'cateringOrder.date') return updateCart({ ...state, date: action.date })
  if (type === 'cateringOrder.time') return updateCart({ ...state, time: action.time })
  if (type === 'cateringOrder.firstname') return updateCart({ ...state, firstname: action.firstname })
  if (type === 'cateringOrder.lastname') return updateCart({ ...state, lastname: action.lastname })
  if (type === 'cateringOrder.email') return updateCart({ ...state, email: action.email })
  if (type === 'cateringOrder.phone') return updateCart({ ...state, phone: action.phone })
  if (type === 'cateringOrder.company') return updateCart({ ...state, company: action.company })
  if (type === 'cateringOrder.address1') return updateCart({ ...state, address1: action.address1 })
  if (type === 'cateringOrder.address2') return updateCart({ ...state, address2: action.address2 })
  if (type === 'cateringOrder.city') return updateCart({ ...state, city: action.city })
  if (type === 'cateringOrder.zip') return updateCart({ ...state, zip: action.zip })
  if (type === 'cateringOrder.comment') return updateCart({ ...state, comment: action.comment })
  if (type === 'cateringOrder.duplicate') return updateCart({ ...state, ...action.duplication })
  if (type === 'cateringOrder.addLunchBox') {
    const items = state.items.slice(0)
    const lunchbox = action.lunchbox

    // Check for similar lunchbox
    const lunchboxSimilar = items.filter(item => item.compositeId === lunchbox.compositeId)[0]
    if (lunchboxSimilar) {
      const lunchboxSimilarIndex = items.indexOf(lunchboxSimilar)
      const quantity = lunchboxSimilar.quantity + lunchbox.quantity
      items[lunchboxSimilarIndex] = { ...lunchboxSimilar, quantity }
    } else {
      items.push({ ...lunchbox })
    }

    return updateCart(state, items)
  }

  if (type === 'cateringOrder.addItem') {
    const items = state.items.slice(0)
    const newItem = action.item

    const itemSimilar = items.filter(item => item.compositeId === newItem.compositeId)[0]
    if (itemSimilar) {
      const itemSimilarIndex = items.indexOf(itemSimilar)
      const quantity = itemSimilar.quantity + newItem.quantity
      items[itemSimilarIndex] = { ...itemSimilar, quantity }
    } else {
      items.push({ ...newItem })
    }

    return updateCart(state, items)
  }
  if (type === 'cateringOrder.removeItem') {
    const items = state.items.slice(0)
    const removeId = action.itemId

    return updateCart({ ...state, items: items.filter(existing => existing.id !== removeId) })
  }
  if (type === 'cateringOrder.removeItemByCompositeId') {
    const items = state.items.slice(0)
    const removeCompositeId = action.compositeId

    return updateCart({ ...state, items: items.filter(existing => existing.compositeId !== removeCompositeId) })
  }
  if (type === 'cateringOrder.increaseQuantity') {
    const items = state.items.slice(0)
    const item = items[action.index]

    if (!item) return state
    // if (item.quantity >= config.maxSingleItems) return state
    // if (state.derived.totalItems >= config.maxTotalItems) return state

    items[action.index] = { ...item, quantity: item.quantity + 1 }
    return updateCart(state, items)
  }
  if (type === 'cateringOrder.decreaseQuantity') {
    const items = state.items.slice(0)
    const item = items[action.index]

    if (!item) return state

    if (item.quantity - 1 <= 0 || item.quantity - 1 < item.minimum) {
      return updateCart({ ...state, items: items.filter(existing => existing !== item) })
    }

    items[action.index] = { ...item, quantity: item.quantity - 1 }
    return updateCart(state, items)
  }
  if (type === 'cateringOrder.increaseQuantityByCompositeId') {
    const items = state.items.slice(0)
    const item = items.filter(existing => existing.compositeId !== action.compositeId)[0]

    if (!item) return state
    const itemIndex = items.indexOf(item)

    items[itemIndex] = { ...item, quantity: item.quantity + 1 }
    return updateCart(state, items)
  }
  if (type === 'cateringOrder.decreaseQuantityByCompositeId') {
    const items = state.items.slice(0)
    const item = items.filter(existing => existing.compositeId !== action.compositeId)[0]

    if (!item) return state
    const itemIndex = items.indexOf(item)

    if (item.quantity - 1 <= 0 || item.quantity - 1 < item.minimum) {
      return updateCart({ ...state, items: items.filter(existing => existing !== item) })
    }

    items[itemIndex] = { ...item, quantity: item.quantity - 1 }
    return updateCart(state, items)
  }
  if (type === 'cateringOrder.setQuantity') {
    const items = state.items.slice(0)
    const item = items[action.index]

    if (!item) return state

    // const quantityLeft = config.maxTotalItems - state.derived.totalItems + item.quantity
    // const quantity = Math.min(action.quantity, config.maxSingleItems, quantityLeft)
    const quantity = Number(action.quantity)

    if (quantity <= 0) {
      return updateCart({ ...state, items: items.filter(existing => existing !== item) })
    }

    items[action.index] = { ...item, quantity }
    return updateCart(state, items)
  }
  if (type === 'cateringOrder.setQuantityByCompositeId') {
    const items = state.items.slice(0)
    const item = items.filter(existing => existing.compositeId === Number(action.compositeId))[0]

    if (!item) return state
    const itemIndex = items.indexOf(item)

    const quantity = Number(action.quantity)

    if (quantity <= 0) {
      return updateCart({ ...state, items: items.filter(existing => existing !== item) })
    }

    items[itemIndex] = { ...item, quantity }
    return updateCart(state, items)
  }
  if (type === 'cateringOrder.clear') {
    return updateCart({ ...state, items: [], fulfillment: '', date: '', time: '1:00 AM', firstname: '', lastname: '', email: '', phone: '', company: '', address1: '', address2: '', city: '', zip: '', comment: '' })
  }

  return updateCart(state)
}

const updateCart = (state, items = null) => {
  if (!items) items = state.items

  const derived = {}

  derived.subtotal = items.map(item => item.price * item.quantity).reduce((a, b) => a + b, 0)
  derived.tax = derived.subtotal * 8.25 / 100 // default tax rate in TX
  derived.tip = state.tipFormat === 'custom' ? state.tip : derived.subtotal / 100 * Number(state.tipFormat)
  derived.total = derived.subtotal + derived.tax + derived.tip
  derived.totalItems = items.map(item => item.quantity).reduce((a, b) => a + b, 0)

  // const anyPlusEnabled = derived.totalItems < config.maxTotalItems
  // derived.anyPlusEnabled = anyPlusEnabled

  const updatedItems = items.map(item => ({ ...item }))

  return { ...state, items: updatedItems, derived, tip: derived.tip }
}

export default cateringOrderReducer
