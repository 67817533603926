import React from 'react'

import { CateringPage, Section, withDependencies } from '../../containers'
import { CateringItem } from '../../components'
import BottomLinks from '../../components/BottomLinks'

class CateringVeggiesPage extends React.Component {
  render () {
    const categories = this.props.catering.cateringCategories

    return (
      <CateringPage containerClasses='is-paddingless'>
        <Section>
          <h2 className='title is-size-4-mobile'>Hot Veggies</h2>
          <p>Our hot vegetable dishes are adored by all our fans. No meal would be complete without a side of our savory veggie creations.</p>
          {categories.VEGG.map(item => <CateringItem item={item} key={item.id} minimums={[1, 1, 1]} />)}
        </Section>
        <Section>
          <h2 className='title is-size-4-mobile'>Rice Dishes</h2>
          <p>Treat your guests to our many traditional rice dishes. Each one is guaranteed to add flare and spice to you meal.</p>
          {categories.RICE.map(item => <CateringItem item={item} key={item.id} minimums={[1, 1, 1]} />)}
        </Section>
        <BottomLinks />
      </CateringPage>
    )
  }
}

export default withDependencies(CateringVeggiesPage, ['catering'], { includeProps: true })
