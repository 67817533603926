/* globals gtag */
import { withFormik } from 'formik'
import axios from 'axios'
import unwrap from 'async-unwrap'

import cateringOrderSchema from '../validation/CateringOrderSchema'
import config from '../config'

const cateringOrderFormWrapper = withFormik({
  validationSchema: cateringOrderSchema,
  handleSubmit: async (formData, { setStatus, resetForm, setSubmitting }) => {
    const sendToApi = { ...formData }
    const [err, res] = await axios.post(`${config.apiUrl}/catering/create-order`, sendToApi)[unwrap] // eslint-disable-line no-unused-vars

    const statusToSet = { initial: false }

    if (err) {
      statusToSet.error = 'Something went wrong. Please try again later.'
      setStatus(statusToSet)
      setSubmitting(false)
      setTimeout(() => {
        setStatus({ ...statusToSet, initial: true })
      }, 5000)
      return
    }

    resetForm()
    statusToSet.success = 'Thank you for submitting your catering order. We will contact you shortly.'
    gtag('event', 'OnlineOrder', {
      event_category: 'CateringOrderComplete',
      event_label: 'CateringOrderComplete',
      value: 1
    })
    setStatus(statusToSet)
    setSubmitting(false)
    setTimeout(() => {
      setStatus({ ...statusToSet, initial: true })
    }, 5000)
  },
  mapPropsToValues: () => ({
    location: '',
    items: [],
    tip: 0,
    fulfillment: '',
    date: '',
    time: '1:00 AM',
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    company: '',
    address1: '',
    address2: '',
    city: '',
    zip: '',
    comment: '',
    recaptcha: ''
  }),
  mapPropsToErrors: () => ({
    readyForSubmission: 'Not ready'
  }),
  mapPropsToStatus: () => ({
    initial: true
  }),
  displayName: 'CateringOrderForm'
})

export default cateringOrderFormWrapper
