/* globals gtag */
import React, { useRef, useState, useCallback, useEffect } from 'react'
import { AcceptHosted } from 'react-authorize-net'
import { connect } from 'react-redux'
// import { RadioGroup, RadioButton } from 'react-radio-buttons'
import querystring from 'querystring'
import useFetch from 'use-http'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faSync, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'
import { detect } from 'detect-browser'

import createAction from '../actions'

import { Hero, Level } from '../containers'
import { NumberInput } from '../components'

import config from '../config'
import IconCard from './IconCard'
import MenuItem from './MenuItem'
import { shouldSayClosed, usePickupTimes } from '../utils/opening-hours'

const browser = detect()

const OrderPreview = ({
  showDebug,
  showOrder,
  exitCheckout,
  clearOrder,
  items,
  location,
  name,
  email,
  phone,
  derived,
  checkout,
  setQuantity,
  increaseQuantity,
  decreaseQuantity,
  tip,
  tipFormat,
  updateTip,
  updateTipFormat,
  displayMenu,
  openMenuModal,
  specialRequest,
  setSpecialRequest
}) => {
  const checkoutRef = useRef()
  const [checkoutLoading, setCheckoutLoading] = useState(false)
  const [paymentMethod] = useState('card')
  const [creditCardLoading, setCreditCardLoading] = useState(true)
  const paymentButtonCallback = useCallback(button => {
    if (button == null) return

    button.click()
  }, [])

  const [frameSize, setFrameSize] = useState({ width: null, height: null })
  const [request] = useFetch(config.apiUrl)

  const [isPaid, setPaid] = useState(false)
  const [paymentResult, setPaymentResult] = useState('none')

  const [isTipCustom, setTipType] = useState(false)
  const [tipAmount, setTipAmount] = useState(tip.toFixed(2))

  const [isMinimumPriceReached, setMinimumPriceReached] = useState(true)

  const [specialRequestModal, setSpecialRequestModal] = useState(false)
  const pickupTimes = [
    ...(!shouldSayClosed(location.name) ? ['ASAP'] : []),
    ...usePickupTimes(location.name)
  ]
  const [selectedPickupTime, setSelectedPickupTime] = useState(pickupTimes[0])

  if (!pickupTimes.includes(selectedPickupTime)) setTimeout(() => setSelectedPickupTime(pickupTimes[0]))

  const scrollToMenu = () => {
    const menuColumn = document.querySelector('.order-layout__main-column')
    if (menuColumn) menuColumn.scrollIntoView({ behavior: 'smooth' })
  }

  const handleTipBlur = val => {
    const tipAmount = isNaN(val) ? '0.00' : parseFloat(val).toFixed(2)
    updateTip(tipAmount)
    setTipAmount(tipAmount)
  }

  const handleTipAmount = val => {
    if (val === 'custom') {
      setTipType(true)
    } else {
      const tipAmount = parseFloat((derived.subtotal / 100 * val).toFixed(2))
      updateTip(tipAmount)
      setTipAmount(tipAmount)
      setTipType(false)
    }
    updateTipFormat(val)
  }

  const tipContent = (
    <div className='field has-addons'>
      <div className='control'>
        <div className='select is-small'>
          <select onChange={ev => handleTipAmount(ev.target.value)} value={tipFormat}>
            <option value=''>Tip</option>
            <option value='10'>10%</option>
            <option value='15'>15%</option>
            <option value='20'>20%</option>
            <option value='custom'>Custom</option>
          </select>
        </div>
      </div>
      <div className='control catering-tip-input'>
        <input type='number' value={tipAmount} className='input is-small has-text-right tip-amount' readOnly={!isTipCustom} onChange={ev => setTipAmount(ev.target.value)} onBlur={ev => handleTipBlur(ev.target.value)} placeholder='Tip Amount' onFocus={ev => ev.target.select()} />
      </div>
    </div>
  )

  useEffect(() => {
    setTipAmount(tip.toFixed(2))
  }, [tip])

  useEffect(() => {
    const handler = async (event) => {
      const message = event.data

      if (event.data.type !== 'authorizenet-update') return

      const data = querystring.parse(message.qstr)

      if (data.action === 'resizeWindow') {
        setFrameSize({
          width: data.width + 'px',
          height: data.height + 'px'
        })
        setCreditCardLoading(false)
      }

      if (data.action === 'transactResponse') {
        if (!checkout.active) console.error('got a transaction but checkout is not enabled', data)

        const response = JSON.parse(data.response)
        // console.log(response)
        // if we need to check anything about the response, do it here

        setPaid(true)
        setPaymentResult('none')

        console.log(response, response.authorization)

        const verification = await request.post('/ordering/verify-payment', {
          orderToken: checkout.data.order.token,
          transactionId: response.transId, // DAMMIT WHY CAN'T YOU BE CONSISTENT FOR ONCE
          authorization: response.authorization,
          email: email
        })

        if (verification && verification.ok) {
          setPaymentResult('success')
          clearOrder()
        } else setPaymentResult('error')
      }

      if (data.action === 'cancel') {
        setPaid(true)
        setPaymentResult('cancel')
        gtag('event', 'OnlineOrder', {
          event_category: 'OrderComplete',
          event_label: 'OrderComplete',
          value: 1
        })
      }
    }

    window.addEventListener('message', handler)
    return () => window.removeEventListener('message', handler)
  })

  useEffect(() => {
    setCreditCardLoading(true) // let's see if this horrible hack works
  }, [(checkout.data.order || {}).token]) // eslint-disable-line

  const generalSettings = useFetch(new URL('/ordering/config/settings', config.apiUrl).href, {}, [])
  const closureSettings = useFetch(new URL('/ordering/config/close', config.apiUrl).href, {}, [])
  const [reminderDisplayed, setReminderDisplayed] = useState(false)
  const [isOnReminder, setOnReminder] = useState(false)
  const [reminderItems, setReminderItems] = useState([])

  const addPitasToDips = !generalSettings.loading && !generalSettings.error && generalSettings.data.ok && generalSettings.data.value.addPitasToDips

  let restaurantClosed = closureSettings.loading || closureSettings.error || !closureSettings.data.ok
  let restaurantClosedError = ''

  if (closureSettings.data) {
    const { start, end } = closureSettings.data.value

    const startDate = new Date(start.replace(/-/g, '/'))
    const endDate = new Date(end.replace(/-/g, '/'))

    endDate.setDate(endDate.getDate() + 1)
    if (!pickupTimes.includes('ASAP') && new Date().getHours() < 12) startDate.setDate(startDate.getDate() - 1)
    //                                                         ^ ugly hack right there

    const now = new Date().getTime()
    if (now > startDate.getTime() && now < endDate.getTime()) {
      restaurantClosed = true
      restaurantClosedError = `Restaurants are closed until ${endDate.toLocaleString('en-us', { weekday: 'long', month: 'long', day: 'numeric' })}`
    }
  }

  const menuItemsById = {}
  displayMenu.forEach(category => category.items.forEach(item => (menuItemsById[item.id] = item)))

  const onCheckout = async () => {
    if (!items.length) return
    if (derived.subtotal < config.minimumSubtotalAmount) {
      setMinimumPriceReached(false)
      setTimeout(() => setMinimumPriceReached(true), 3000)
      return
    }

    if (!generalSettings.loading && !generalSettings.error && generalSettings.data.ok) {
      const orderedItems = items.map(item => item.id)

      const checkoutReminders = generalSettings.data.value.checkoutReminders
        .filter(reminder => reminder !== -1) // filter out enabled reminders
        .filter(reminder => !orderedItems.includes(reminder)) // filter out items the user already ordered
        .filter(reminder => menuItemsById[reminder]) // filter for items actually on the menu
      if (checkoutReminders.length && !reminderDisplayed) {
        setReminderItems(checkoutReminders)
        setOnReminder(true)
        setReminderDisplayed(true)
        return
      }
    }
    setOnReminder(false)

    const order = {
      name,
      location: location.id,
      tip,
      items: items.map(item => {
        const { id, type, quantity, items } = item

        if (item.type === 'item') return { id, type, quantity }
        if (item.type === 'platter') {
          return {
            id,
            type,
            quantity,
            items: items.map(selections => selections.map(thing => thing.id))
          }
        }

        return null
      }).filter(value => value !== null),
      specialRequest,
      email,
      phone,
      pickupTime: selectedPickupTime
    }

    setCheckoutLoading(true)

    const init = {
      method: 'post',
      headers: new window.Headers({ 'content-type': 'application/json' }),
      body: JSON.stringify(order)
    }
    console.log(init)
    const result = await window.fetch(new window.URL('/ordering/submit', config.apiUrl).href, init)
    const json = await result.json()

    setCheckoutLoading(false)

    // showDebug(json)
    showOrder(json)
    // clearOrder()
  }

  const cancelOrder = () => {
    if (checkout.active) {
      request.post('/ordering/cancel-order', {
        orderToken: checkout.data.order.token
      })
    }
    exitCheckout()
  }

  const deferOrder = async () => {
    if (!checkout.active) return console.error("It's outrageous! It's unfair! How can you be on deferring an order and not have checkout active?")
    setPaid(true)
    setPaymentResult('none')
    const result = await request.post('/ordering/defer-order', {
      orderToken: checkout.data.order.token,
      email: email
    })

    if (result && result.ok) {
      setPaymentResult('success')
      clearOrder()
    } else setPaymentResult('error')
  }

  const done = () => {
    setPaid(false)
    setReminderDisplayed(false)
    exitCheckout()
    scrollToMenu()
  }

  const renderedItems = items.map(item => {
    return {
      name: item.name,
      quantity: item.quantity,
      description: item.type === 'platter' ? [
        ...(item.config.automaticItems || []),
        ...item.items.flat().map(selection => selection.name)
      ].join('\n') : (
        item.category.toLowerCase() === 'dips' && addPitasToDips ? '1 pita included' : ''
      ),
      price: (item.price * item.quantity).toFixed(2),
      plusEnabled: item.plusEnabled,
      limitReached: item.quantity === config.maxSingleItems
    }
  })

  const checkoutDisabled = checkoutLoading || !renderedItems.length || restaurantClosed

  const heroClasses = classNames('is-fullheight order-preview', {
    'order-preview--ios': browser.os === 'iOS'
  })

  if (isPaid) {
    const paymentLoading = paymentResult === 'none'

    return (
      <Hero
        classes={heroClasses} innerClasses='order-preview__item-spacer' headClasses='order-preview__head' head={
          <>
            <Level
              classes='is-mobile order-preview__head-title' left='Order Complete' right={
                <button
                  className={classNames('button is-dark is-small', {
                    'is-loading': paymentLoading
                  })} disabled={paymentLoading} onClick={done}
                >Done
                </button>
              }
            />
            <div className='order-preview__thank-you-wrapper'>
              {
                (request.error || paymentResult === 'error') && (
                  <div className='message is-error'>
                    <div className='message-header'>
                      <p>Error</p>
                    </div>
                    <div className='message-body'>
                      Order cannot be completed (server error)
                    </div>
                  </div>
                )
              }
              {
                request.loading && (
                  <div className='order-preview__thank-you-message'>
                    <div className='order-preview__thank-you-icon'>
                      <FontAwesomeIcon icon={faSync} size='3x' spin />
                    </div>
                  </div>
                )
              }
              {
                (paymentResult !== 'success') ? undefined : (
                  <div className='order-preview__thank-you-message has-text-dark'>
                    <div className='order-preview__thank-you-icon'>
                      <FontAwesomeIcon icon={faCheckCircle} size='3x' />
                    </div>
                    <div className='order-preview__thank-you-text'>
                      Thank you
                    </div>
                    <div className='order-preview__thank-you-long'>
                      Your order has ben successfully submitted and will be ready
                      at the location selected. A confirmation email with order
                      details has been sent to your email address. Thank you for
                      your order.
                    </div>
                  </div>
                )
              }
              {
                (paymentResult !== 'cancel') ? undefined : (
                  <div className='order-preview__thank-you-message has-text-danger'>
                    <div className='order-preview__thank-you-icon'>
                      <FontAwesomeIcon icon={faTimesCircle} size='3x' />
                    </div>
                    <div className='order-modal__thank-you-text'>
                      Payment cancelled
                      <br />
                      <button className='button' onClick={() => setPaid(false)}>Retry</button>
                    &nbsp;
                      <button className='button is-danger' onClick={done}>Cancel order</button>
                    </div>
                  </div>
                )
              }
            </div>
          </>
        } footClasses='order-preview__foot' foot={
          <button
            className={classNames('order-preview__checkout-button button is-fullwidth is-dark', {
              loading: paymentLoading
            })} disabled={paymentLoading} onClick={done}
          >
            Done
          </button>
        }
      />
    )
  }

  if (isOnReminder) {
    return (
      <Hero
        classes={heroClasses} innerClasses='order-preview__item-spacer' headClasses='order-preview__head' head={
          <>
            <Level
              classes='is-mobile order-preview__head-title' left='Checkout' right={
                <button className='button is-danger is-small' onClick={() => setOnReminder(false)}>
                  <FontAwesomeIcon icon={faArrowLeft} />
                  &nbsp;
                  Edit Order
                </button>
              }
            />
            <h2 className='subtitle'>Would you like to add some of these items to your cart?</h2>
            <div className='order-preview__reminder-items'>
              {
                reminderItems.map(itemId => {
                  const item = menuItemsById[itemId]

                  return (
                    <IconCard
                      classes='home-page__menu-card'
                      imageClasses='button home-page__menu-card-image'
                      onClick={() => openMenuModal(item)}
                      image={item.image}
                      key={itemId}
                    >
                      <MenuItem item={item} />
                    </IconCard>
                  )
                })
              }
            </div>
          </>
        } footClasses='order-preview__foot' foot={
          <>
            <button className='order-preview__checkout-button button is-fullwidth is-danger' onClick={() => setOnReminder(false)}>
              Edit Order
            </button>
            <button className='order-preview__checkout-button button is-fullwidth is-dark' onClick={onCheckout} disabled={checkoutDisabled}>
              Proceed to Checkout
            </button>
          </>
        }
      />
    )
  }

  if (checkout.active) {
    const orderConfig = checkout.data.order.config

    if (!orderConfig) {
      return (
        <Hero
          classes={heroClasses} innerClasses='order-preview__item-spacer' headClasses='order-preview__head' head={
            <>
              <Level
                classes='is-mobile order-preview__head-title' left='Checkout' right={
                  <button className='button is-danger is-small' onClick={cancelOrder}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                    &nbsp;
                    Back to Order
                  </button>
                }
              />
              <div className='message is-error'>
                <div className='message-header'>
                  <p>Error</p>
                </div>
                <div className='message-body'>
                  Order cannot be completed (server error)
                </div>
              </div>
            </>
          } footClasses='order-preview__foot' foot={
            <button className='order-preview__checkout-button button is-fullwidth is-danger' onClick={cancelOrder}>
              <FontAwesomeIcon icon={faArrowLeft} />
              &nbsp;
              Back to Order
            </button>
          }
        />
      )
    }

    const pickupTime = checkout.time ? new Date(new Date(checkout.time).getTime() + 20 * 60 * 1000).toLocaleString('en-us', { hour: 'numeric', minute: 'numeric', day: 'numeric', month: 'short' }) : null
    const fancyPickupTime = orderConfig.pickupTime === 'ASAP' ? '20 minutes' : orderConfig.pickupTime

    return (
      <Hero
        classes={heroClasses} innerClasses='order-preview__item-spacer' headClasses='order-preview__head' head={
          <>
            <Level
              classes='is-mobile order-preview__head-title' left='Checkout' right={
                <button className='button is-danger is-small' onClick={cancelOrder}>
                  <FontAwesomeIcon icon={faArrowLeft} />
                  &nbsp;
                  Edit Order
                </button>
              }
            />
            <Level classes='order-preview__header-row is-mobile' left='Order Summary' right='' />
            <Level classes='is-mobile order-preview__detail' left='Name:' right={<strong>{orderConfig.name}</strong>} />
            <Level classes='is-mobile order-preview__detail' left='Email:' right={<strong>{email}</strong>} />
            <Level classes='is-mobile order-preview__detail' left='Location:' right={<strong>{orderConfig.location.name}</strong>} />
            <Level classes='is-mobile order-preview__detail' left='Pickup Time:' right={<strong>{fancyPickupTime}</strong>} />
            <Level classes='is-mobile order-preview__detail' left='' right={orderConfig.pickupTime === 'ASAP' ? pickupTime : ''} />
            <div>&nbsp;</div>
            <Level classes='is-mobile order-preview__detail' left='Order:' right={<strong>{orderConfig.items.length} Items</strong>} />
            <Level classes='is-mobile order-preview__detail' left='Subtotal:' right={orderConfig.subtotal.toFixed(2)} />
            <Level classes='is-mobile order-preview__detail' left='Tip:' right={orderConfig.tip.toFixed(2)} />
            <Level classes='is-mobile order-preview__detail' left='Tax:' right={orderConfig.tax.toFixed(2)} />
            <Level classes='is-mobile order-preview__detail' left='Grand Total:' right={<strong>${orderConfig.price.toFixed(2)}</strong>} />
            <div>&nbsp;</div>
            <Level classes='order-preview__header-row is-mobile' left='Payment' right='' />
            {/* <RadioGroup horizontal value={paymentMethod} onChange={value => setPaymentMethod(value)}>
            <RadioButton value='card' iconSize={20} iconInnerSize={10} rootColor='hsl(0, 0%, 71%)' pointColor='hsl(171, 100%, 41%)'>
              Credit Card
            </RadioButton>
            <RadioButton value='defer' iconSize={20} iconInnerSize={10} rootColor='hsl(0, 0%, 71%)' pointColor='hsl(171, 100%, 41%)'>
              At Pickup
            </RadioButton>
          </RadioGroup> */}
            <div className='order-preview__payment-box'>
              {
                paymentMethod === 'card' ? (
                  <>
                    <div className={classNames('order-preview__credit-card-loading has-text-centered', {
                      'order-preview__credit-card-loading--visible': creditCardLoading,
                      'order-preview__credit-card-loading--hidden': !creditCardLoading
                    })}
                    >
                      <FontAwesomeIcon icon={faSync} spin />
                      &nbsp;&nbsp;
                      Loading credit card input...
                    </div>
                    <div
                      className='order-preview__payment-form' style={{
                        // width: frameSize.width,
                        width: window.innerWidth < 440 ? 300 : 400,
                        height: frameSize.height
                      }}
                    >
                      <AcceptHosted
                        formToken={checkout.active ? checkout.data.order.paymentFormToken : null}
                        type='iframe'
                        mode='production'
                        onResize={(...args) => console.log('stuff resized', args)}
                        onTransact={(response) => console.log('got transaction', response)}
                        onMessage={(message) => console.log('got message', message)}
                      >
                        <button className='button is-dark' ref={paymentButtonCallback}>
                          Pay now
                        </button>
                      </AcceptHosted>
                    </div>
                  </>
                ) : (
                  <button className='button is-primary is-fullwidth' onClick={deferOrder}>Continue</button>
                )
              }
            </div>
          </>
        } footClasses='order-preview__foot' foot={
          <button className='order-preview__checkout-button button is-fullwidth is-danger' onClick={cancelOrder}>
            <FontAwesomeIcon icon={faArrowLeft} />
            &nbsp;
            Edit Order
          </button>
        }
      />
    )
  }

  return (
    <Hero
      classes={heroClasses} innerClasses='order-preview__item-spacer' headClasses='order-preview__head' head={
        <>
          <Level
            classes='is-mobile order-preview__head-title' left='Current Order' rightClasses='order-preview__back-to-menu-button' right={
              <button
                className={classNames('button is-dark is-small', {
                  // 'is-loading': checkoutLoading
                })}
                // disabled={checkoutDisabled} onClick={onCheckout}
                onClick={scrollToMenu}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
                &nbsp;
                Back to Menu
              </button>
            }
          />
          <Level classes='order-preview__header-row is-mobile' left='Item' right='Qty' />
          {
            !derived.anyPlusEnabled ? (
              <div className='order-preview__tooltip has-text-danger'>
                You can order a maximum of {config.maxTotalItems} items
              </div>
            ) : undefined
          }
          {
            !isMinimumPriceReached ? (
              <div className='order-preview__tooltip has-text-danger'>
                Minimum order is ${config.minimumSubtotalAmount}
              </div>
            ) : undefined
          }
          {
            renderedItems.map((item, index) => (
              <div className='order-preview__item' key={index}>
                <Level
                  classes='order-preview__item-title-row is-mobile' leftClasses='order-preview__item-title' left={
                    item.name
                  } rightClasses='order-preview__item-quantity' right={
                    <NumberInput
                      value={item.quantity}
                      onChange={event => setQuantity(index, event.target.value)}
                      onIncrease={() => increaseQuantity(index)}
                      onDecrease={() => decreaseQuantity(index)}
                      plusDisabled={!item.plusEnabled}
                      small
                    />
                  }
                />
                <div className='order-preview__item-description'>{item.description}</div>
                <div className='order-preview__item-price'>${item.price}</div>
                {
                  item.limitReached ? (
                    <div className='order-preview__item-tooltip has-text-danger'>
                      You can order a maximum of {config.maxSingleItems} of this item
                    </div>
                  ) : undefined
                }
              </div>
            ))
          }
        </>
      } footClasses='order-preview__foot' foot={
        <>
          <Level classes='order-preview__tax-row is-mobile' left='Sub-total' right={`$${derived.subtotal.toFixed(2)}`} />
          <Level classes='order-preview__tax-row is-mobile' left='Tax' right={`$${derived.tax.toFixed(2)}`} />
          <Level classes='order-preview__tax-row is-mobile' left='Tip' right={tipContent} /><br />
          <Level classes='order-preview__total-row is-mobile' left='Total' right={`$${derived.total.toFixed(2)}`} />
          <div className={classNames('modal order-preview__special-request-modal', {
            'is-active': specialRequestModal
          })}
          >
            <div className='modal-background' onClick={() => setSpecialRequestModal(false)} />
            <div className='modal-card'>
              <div className='modal-card-head'>
                Special Requests
              </div>
              <div className='modal-card-body'>
                <textarea className='textarea has-fixed-size' placeholder='Type special requests here' value={specialRequest} onChange={setSpecialRequest} rows={2} />
              </div>
              <div className='modal-card-foot has-text-right'>
                <button className='button' onClick={() => setSpecialRequestModal(false)}>
                  Save
                </button>
              </div>
            </div>
          </div>
          <div className='field'>
            <button className='button is-fullwidth' onClick={() => setSpecialRequestModal(true)}>
              Enter Comment / Special Request
            </button>
          </div>
          <div className='field'>
            <div className='select is-fullwidth'>
              <select value={selectedPickupTime} onChange={(event) => setSelectedPickupTime(event.target.value)} disabled={checkoutDisabled}>
                {
                  pickupTimes.map(time => (
                    <option key={time} value={time}>
                      {
                        time === 'ASAP'
                          ? 'Pickup in 20 minutes'
                          : `Pickup at ${time}`
                      }
                    </option>
                  ))
                }
              </select>
            </div>
          </div>
          <button
            className={classNames('order-preview__checkout-button button is-fullwidth is-dark', {
              'is-loading': checkoutLoading
            })} ref={checkoutRef} onClick={onCheckout} disabled={checkoutDisabled}
          >
            Checkout
          </button>
          {
            restaurantClosedError
              ? (
                <p className='help is-danger has-text-bold'>{restaurantClosedError}</p>
                )
              : undefined
          }
          <div className='order-preview__ios-bar' />
        </>
      }
    >
      {
        !renderedItems.length && window.innerWidth <= 768 ? (
          <div className='order-preview__back-button'>
            <button className='button' onClick={scrollToMenu}>Back to Menu</button>
          </div>
        ) : undefined
      }
    </Hero>
  )
}

export default connect(
  state => ({
    items: state.order.items,
    derived: state.order.derived,
    location: state.order.location,
    name: state.order.name,
    email: state.order.email,
    phone: state.order.phone,
    checkout: state.order.checkout,
    tip: state.order.tip,
    tipFormat: state.order.tipFormat,
    displayMenu: state.loaders.loaded.includes('menu') ? state.loaders.values.menu.displayMenu : [],
    specialRequest: state.order.specialRequest
  }),
  dispatch => ({
    increaseQuantity: (index) => dispatch(createAction('order.increaseQuantity', { index })),
    decreaseQuantity: (index) => dispatch(createAction('order.decreaseQuantity', { index })),
    setQuantity: (index, quantity) => dispatch(createAction('order.setQuantity', { index, quantity })),
    showDebug: (output) => dispatch(createAction('modal.open', { name: 'debug', data: { output } })),
    showOrder: (order) => dispatch(createAction('order.checkout', { data: { order } })),
    exitCheckout: () => dispatch(createAction('order.exitCheckout')),
    clearOrder: (output) => dispatch(createAction('order.clear')),
    updateTip: (tip) => dispatch(createAction('order.tip', { tip })),
    updateTipFormat: (tipFormat) => dispatch(createAction('order.tipFormat', { tipFormat })),
    openMenuModal: (item) => dispatch(createAction('modal.open', { name: 'menu', data: { item } })),
    setSpecialRequest: (event) => dispatch(createAction('order.setSpecialRequest', { specialRequest: event.target.value }))
  })
)(OrderPreview)
