import React from 'react'

const formRequired = props => {
  const messagesToDisplay = Object.keys(props.messages).map(key => <li key={key}>{props.messages[key]}</li>)
  return (
    <div className={`message is-warning${props.visible ? '' : ' is-hidden'}`}>
      <div className='message-header'>
        <p>Please complete the following before submitting:</p>
      </div>
      <div className='message-body content'>
        <ul className='has-no-top-margin'>
          {messagesToDisplay}
        </ul>
      </div>
    </div>
  )
}

export default formRequired
