import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { faCartPlus } from '@fortawesome/free-solid-svg-icons'

import createAction from '../actions'

import { Level } from '../containers'
import { BlippedButton } from '.'

class MenuItem extends React.Component {
  constructor () {
    super()

    this.handleAdd = () => {
      const item = this.props.item
      if (!item) return

      if (item.type === 'item') this.props.addToCart(item)
      if (item.type === 'platter') {
        this.props.setPlatter(item)
        this.props.history.push('/platter')
      }
      if (item.type === 'container') this.props.openMenuModal(item)
    }
  }

  render () {
    const item = this.props.item
    const { name, price, id, type } = item

    const cartItems = this.props.cart.filter(item => item.type === type && item.id === id)
    const cartItem = cartItems[0]
    const blip = cartItems.map(item => item.quantity).reduce((a, b) => a + b, 0)
    const disabled = (cartItem ? !cartItem.plusEnabled : !this.props.anyPlusEnabled) || this.props.checkoutActive

    return (
      <div className='menu-item'>
        <div className='menu-item__name'>
          <div className='menu-item__name-inner'>
            {name}
          </div>
        </div>
        <Level
          classes='menu-item__price-row is-mobile' left={
            type === 'container' ? 'Pick size' : `$${Number(price).toFixed(2)}`
          } right={
            <BlippedButton icon={faCartPlus} blip={blip} onClick={this.handleAdd} disabled={disabled} />
          }
        />
      </div>
    )
  }
}

export default connect(
  state => ({
    cart: state.order.items,
    checkoutActive: state.order.checkout.active,
    anyPlusEnabled: state.order.derived.anyPlusEnabled
  }),
  dispatch => ({
    addToCart: (item) => dispatch(createAction('order.addItem', { item })),
    setPlatter: (platter) => dispatch(createAction('platter.set', { platter })),
    openMenuModal: (item) => dispatch(createAction('modal.open', { name: 'menu', data: { item } }))
  })
)(withRouter(MenuItem))
