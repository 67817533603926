import { combineReducers } from 'redux'

import loaders from './loaders'
import modal from './modal'
import order from './order'
import platter from './platter'
import cateringOrder from './cateringOrder'

const rootReducer = combineReducers({
  loaders,
  modal,
  order,
  platter,
  cateringOrder
})

export default rootReducer
