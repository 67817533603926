// redux store

import { createStore, applyMiddleware, compose } from 'redux'
import ReduxThunk from 'redux-thunk'
import { debounce } from 'debounce'
import shajs from 'sha.js' // to avoid importing nodejs crypto, which is a huge module

import rootReducer from './reducers'

const persistentState = loadState()

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
  rootReducer,
  persistentState,
  composeEnhancers(
    applyMiddleware(ReduxThunk)
  )
)

export default store

function calculateScriptFingerprint () {
  const scriptNames = Array.from(document.querySelectorAll('script'))
    .map(tag => tag.src)
    .filter(a => a)
    .filter(name => {
      const url = new URL(name)
      return url.pathname.startsWith('/static/js')
    })
    .sort()
    .join(':')

  return shajs('sha256').update(scriptNames).digest('hex')
}
const scriptFingerprint = calculateScriptFingerprint()

// save to localstorage
store.subscribe(debounce(() => {
  const state = store.getState()

  if (window.localStorage) {
    window.localStorage.setItem('persistentState', JSON.stringify(state))
    window.localStorage.setItem('persistentStateFingerprint', scriptFingerprint)
  }
}, 200))

function loadState () {
  if (!window.localStorage) return undefined

  // // detect reload, do not load state
  // if (window.performance.getEntriesByType) {
  //   const navigationEntry = window.performance.getEntriesByType('navigation')[0]
  //   if (navigationEntry && navigationEntry.type === 'reload') return undefined
  // } else if (window.performance.navigation) {
  //   if (window.performance.navigation.type === 1) return undefined
  // }

  // check for hard reset
  if (window.localStorage.getItem('persistentStateNeedsReload')) {
    window.localStorage.removeItem('persistentState')
    window.localStorage.removeItem('persistentStateNeedsReload')
    return undefined
  }

  // check for fingerprint mismatch
  if (window.localStorage.getItem('persistentStateFingerprint') !== calculateScriptFingerprint()) {
    if (window.location.hostname === 'localhost') {
      console.warn('State wipe aborted (running on localhost), trigger a hard refresh if working on redux')
    } else {
      window.localStorage.removeItem('persistentState')
      window.localStorage.removeItem('persistentStateNeedsReload')
      return undefined
    }
  }

  try {
    const loadedState = JSON.parse(window.localStorage.getItem('persistentState'))
    return loadedState || undefined
  } catch (err) {
    console.error('State could not be loaded', err)
    return undefined
  }
}

document.body.addEventListener('keydown', (event) => {
  // capture Ctrl+Shift+R (or Cmd+Shift+R because MacOS has to be "special") and flag a hard reset

  if ((event.ctrlKey || event.metaKey) && event.shiftKey && event.key === 'R') {
    window.localStorage.setItem('persistentStateNeedsReload', true)
  }
})
