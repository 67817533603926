import React from 'react'

import { CateringPage, Section, withDependencies } from '../../containers'
import { CateringItem } from '../../components'
import BottomLinks from '../../components/BottomLinks'

class CateringGrillPage extends React.Component {
  render () {
    const categories = this.props.catering.cateringCategories

    return (
      <CateringPage containerClasses='is-paddingless'>
        <Section>
          <h2 className='title is-size-4-mobile'>Gyro Cart</h2>
          <p>Treat your guests to a new experience! Our gyro cart delivers everything you need to serve up fresh gyro from our rotating spit. One of our own chefs will cook and prepare each of your guest's gyro to their liking. Price includes preparation, pitas, plates, utensils & all sides. Approximately 20lbs spit-roast skewer. Your choice of chicken shawarma, beef shawarma or gyro. <strong>A minimum of 3 hour chef service per cart is required,</strong> additional service hours can be entered below.</p>
          {categories.CART.map(item => <CateringItem item={item} key={item.id} mask={[true, true]} labels={['Gyro Cart', 'Chef Service']} descriptions={['approx. 20 lbs', 'min 3 hrs per cart']} minimums={[1, 3]} />)}
        </Section>
        <Section>
          <h2 className='title is-size-4-mobile'>Big Feast</h2>
          <p>Gather your guests for an unforgettable feast. Your lamb will be stuffed with rice, almonds, ground beef & peanuts topped with mixed nuts. Absolutely delicious.</p>
          {categories.BFST.map(item => <CateringItem item={item} key={item.id} mask={[true, true]} labels={['Medium Lamb', 'Large Lamb']} descriptions={['20-30 lbs (Whole)', '30-35 lbs (Whole)']} />)}
        </Section>
        <Section>
          <h2 className='title is-size-4-mobile'>From the Grill</h2>
          <p>The heart of our dining experience. Our chefs will prepare your meat entrées to perfection. A one pound serving (1 lbs) will serve 2 - 3 people.</p>
          {categories.MEAT.map(item => <CateringItem item={item} key={item.id} mask={[true]} labels={['Per Serving']} descriptions={['or as specified']} />)}
        </Section>
        <BottomLinks />
      </CateringPage>
    )
  }
}

export default withDependencies(CateringGrillPage, ['catering'], { includeProps: true })
