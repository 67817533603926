import React from 'react'
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheck, faTimes, faAngleLeft, faAngleRight, faPaperPlane, faPlus, faMinus, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import HomePage from './pages/Home'
import PlatterPage from './pages/Platter'
import PrivacyPolicyPage from './pages/PrivacyPolicy'
import TermsOfService from './pages/TermsOfService'

import CateringHomePage from './pages/catering'
import CateringAppetizersPage from './pages/catering/appetizers'
import CateringDessertsPage from './pages/catering/desserts'
import CateringGrillPage from './pages/catering/grill'
import CateringLunchboxPage from './pages/catering/lunchbox'
import CateringSaladsPage from './pages/catering/salads'
import CateringVeggiesPage from './pages/catering/veggies'
import CateringCartPage from './pages/catering/cart'
import CateringClone from './pages/catering/clone'

import { ModalContainer } from './containers'

import './App.sass' // Kenobi's favorite format

// import logo from './logo.svg'
// import './App.css'

library.add(faCheck, faTimes, faAngleLeft, faAngleRight, faPaperPlane, faPlus, faMinus, faTimesCircle)

function App () {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/' exact render={(...props) => <HomePage {...props} />} />
        <Route path='/platter' exact render={(...props) => <PlatterPage {...props} />} />
        <Route path='/privacy-policy' exact render={(...props) => <PrivacyPolicyPage {...props} />} />
        <Route path='/terms-of-service' exact render={(...props) => <TermsOfService {...props} />} />

        <Route path='/catering/appetizers' exact render={(...props) => <CateringAppetizersPage {...props} />} />
        <Route path='/catering/desserts' exact render={(...props) => <CateringDessertsPage {...props} />} />
        <Route path='/catering/grill' exact render={(...props) => <CateringGrillPage {...props} />} />
        <Route path='/catering/lunchbox' exact render={(...props) => <CateringLunchboxPage {...props} />} />
        <Route path='/catering/salads' exact render={(...props) => <CateringSaladsPage {...props} />} />
        <Route path='/catering/veggies' exact render={(...props) => <CateringVeggiesPage {...props} />} />
        <Route path='/catering/cart' exact render={(...props) => <CateringCartPage {...props} />} />
        <Route path='/catering/clone/:token' exact render={props => <CateringClone {...props} />} />
        <Route path='/catering' exact render={(...props) => <CateringHomePage {...props} />} />

        <Route path='/:location' exact render={(...props) => <HomePage {...props} />} />
        <Route path='/food/:food' exact render={(...props) => <HomePage {...props} />} />
        <Route render={(...props) => <Redirect to='/' />} />
      </Switch>
      <ModalContainer />
    </BrowserRouter>
  )
}

export default App
