import React, { useState, useEffect } from 'react'
import { EventEmitter } from 'events'
import classNames from 'classnames'

import { Container } from '../containers'

const cheatCodeEmitter = new EventEmitter()
cheatCodeEmitter.setMaxListeners(921)

let recordedCode = []
window.addEventListener('keydown', (event) => {
  recordedCode = [...recordedCode, event.key].slice(-10)
  if (recordedCode.join('-') === 'ArrowUp-ArrowUp-ArrowDown-ArrowDown-ArrowLeft-ArrowRight-ArrowLeft-ArrowRight-b-a') {
    cheatCodeEmitter.emit('polarize')
  }
})

const IconCard = ({ classes, innerClasses, image, imageClasses, platter, disabled, onClick, imageOverlay, children }) => {
  const [cardDesign, setCardDesign] = useState('card') // useState(platter ? 'card' : 'hidden')
  const [rotation, setRotation] = useState(0)

  useEffect(() => {
    const handler = () => {
      setCardDesign('polaroid')
      setRotation(Math.random() * 6 - 3)
    }
    cheatCodeEmitter.addListener('polarize', handler)

    return () => cheatCodeEmitter.removeListener('polarize', handler)
  }, [setCardDesign])

  return (
    <Container classes={classNames('is-centered has-text-centered', classes)}>
      {/* to show the images again: move the div out of the "platter" condition and uncomment the image */}
      <div
        className={classNames('icon-card__image-wrapper', {
          'icon-card__image-wrapper--polaroid': cardDesign === 'polaroid'
        })} style={{
          transform: cardDesign === 'polaroid' ? `rotate(${rotation}deg)` : ''
        }}
      >
        <div
          className={classNames('icon-card__image', imageClasses, {
            'icon-card__image--hidden': cardDesign === 'hidden',
            'icon-card__image--card': cardDesign === 'card',
            'icon-card__image--polaroid': cardDesign === 'polaroid'
          })} style={{
            backgroundImage: `url(${image})`
          }} onClick={disabled ? undefined : onClick} disabled={disabled}
        >
          {imageOverlay}
        </div>
      </div>
      <div className={innerClasses}>{children}</div>
    </Container>
  )
}

export default IconCard
