import React from 'react'

import { NumberInput } from '.'

class NumberInputManaged extends React.Component {
  constructor () {
    super()

    this.state = {
      value: 0
    }

    this.filterValue = (rawValue) => {
      const notEmptystring = rawValue === '' ? 0 : rawValue
      const minimumApplied = !isNaN(this.props.minValue) ? Math.max(notEmptystring, this.props.minValue) : notEmptystring
      const maximumApplied = !isNaN(this.props.maxValue) ? Math.min(minimumApplied, this.props.maxValue) : minimumApplied

      return maximumApplied
    }
    this.getValue = () => this.filterValue(Number(this.props.value) || this.state.value)
    this.handleChange = (newValue) => {
      const value = this.filterValue(newValue)
      this.setState({ value })
      if (typeof this.props.onChange === 'function') this.props.onChange({ target: { value }, ...this.props.addToValue })
    }

    this.handleChangeEvent = (event) => this.handleChange(event.target.value)
    this.handleIncrease = () => this.handleChange(Math.min(this.getValue() + 1, Number(this.props.maxValue) || +Infinity))
    this.handleDecrease = () => this.handleChange(Math.max(this.getValue() - 1, Number(this.props.minValue) || -Infinity))
  }

  componentDidMount () {
    const value = Number(this.props.value) || Number(this.props.defaultValue) || this.state.value
    this.setState({ value })
  }

  render () {
    const remainingProps = { ...this.props }
    delete remainingProps.defaultValue
    delete remainingProps.addToValue

    const value = this.getValue()
    const plusDisabled = this.props.plusDisabled || !(!isNaN(this.props.maxValue) ? value < this.props.maxValue : true)
    const minusDisabled = this.props.minusDisabled || !(!isNaN(this.props.minValue) ? value > this.props.minValue : true)

    return (
      <NumberInput
        {...remainingProps}
        value={value}
        onChange={this.handleChangeEvent}
        onIncrease={this.handleIncrease}
        onDecrease={this.handleDecrease}
        plusDisabled={plusDisabled}
        minusDisabled={minusDisabled}
      />
    )
  }
}

export default NumberInputManaged
