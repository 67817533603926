import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { faCartPlus } from '@fortawesome/free-solid-svg-icons'

import createAction from '../actions'

import { NumberInputManaged, BlippedButton } from '.'
import config from '../config'
import CateringInCartIndicator from './CateringInCartIndicator'

class CateringItem extends React.Component {
  state = {
    quantitytier1: this.props.minimums ? this.props.minimums[0] : 1,
    quantitytier2: this.props.minimums ? this.props.minimums[1] : 1,
    quantitytier3: this.props.minimums ? this.props.minimums[2] : 1
  }

  handleAddItem = (compositeId, tier, description) => {
    const item = this.props.item
    const price = item[`tier${tier}`]
    const quantity = this.state[`quantitytier${tier}`]
    const type = 'item'
    const minimum = this.props.minimums ? this.props.minimums[0] : false
    const plusEnabled = this.props.plusEnabled || true
    const readOnly = false
    const minusEnabled = true
    const { id, name, category, code } = item
    this.props.addToCart({ id, name, type, category, code, price, quantity, minimum, compositeId, tier, description, plusEnabled, minusEnabled, readOnly })
  }

  handleQtyChange = (ev, compositeId, minimum, tier) => {
    const targetValue = ev.target.value
    const isInCart = this.props.cart.filter(cartItem => cartItem.compositeId === compositeId)[0]
    const inCartAmount = isInCart ? isInCart.quantity : 0
    if (inCartAmount !== 0) {
      if (targetValue < minimum) {
        this.handleItemRemoval(compositeId)
      } else {
        this.props.setQtyByCompositeId(compositeId, targetValue)
      }
    }
    this.setState({ ...this.state, [`quantitytier${tier}`]: targetValue })
  }

  handleItemRemoval = (compositeId, minimum, tier) => {
    this.props.removeItemByCompositeId(compositeId)
    this.setState({ ...this.state, [`quantitytier${tier}`]: minimum })
  }

  handleValue = (inCartAmount, tier) => {
    if (inCartAmount > 0) return inCartAmount
    return this.state[`quantitytier${tier}`]
  }

  render () {
    const item = this.props.item
    const cartItems = this.props.cart

    const mask = this.props.mask || [true, true, true]
    const labels = this.props.labels || ['Small Tray', 'Medium Tray', 'Large Tray']
    const descriptions = this.props.descriptions || ['Serves 10-15', 'Serves 15-25', 'Serves 30-40']
    const prices = [item.tier1, item.tier2, item.tier3]
    const minimums = this.props.minimums || [1, 1, 1]
    const maximums = this.props.maximiums || [config.maxCateringItems, config.maxCateringItems, config.maxCateringItems]
    let inCartAmount = 0

    return (
      <div className='catering-item'>
        <div className='catering-item__name'>{item.name}</div>
        <div className='catering-item__options columns'>
          {mask.map((enabled, index) => {
            if (!enabled) return undefined

            const price = prices[index]
            if (!price && !this.props.includeUnpriced) return undefined

            const description = prices[1] ? labels[index] : ''

            const tier = index + 1

            const compositeId = item.id * 10 + index

            const isInCart = cartItems.filter(cartItem => compositeId === cartItem.compositeId)[0]
            inCartAmount = isInCart ? isInCart.quantity : 0
            const minimumAmount = isInCart ? minimums[index] - 1 : minimums[index]

            return (
              <div className='catering-item__option column is-one-third' key={index}>
                <div className='catering-item__option-text-column'>
                  <div className='catering-item__option-title'>{labels[index]}</div>
                  <div className='catering-item__option-description'>{descriptions[index]}</div>
                </div>
                <div className='catering-item__option-input-column'>
                  <div className='catering-item__option-input'>
                    <NumberInputManaged small classes='catering-item__option-number-input' minValue={minimumAmount} maxValue={maximums[index]} onChange={ev => this.handleQtyChange(ev, compositeId, minimums[index], tier)} value={this.handleValue(inCartAmount, tier)} />
                    <div className='catering-item__option-input-spacer' />
                    {!isInCart && <BlippedButton icon={faCartPlus} innerClasses='is-small' onClick={() => this.handleAddItem(compositeId, tier, description)} disabled={isInCart} />}
                    {isInCart && <CateringInCartIndicator amount={inCartAmount} handleRemoval={() => this.handleItemRemoval(compositeId, minimums[index], tier)} />}
                  </div>
                  <div className='catering-item__option-price-indicator'>
                    <div className='catering-item__option-price'>@ {price.toFixed(2)}</div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    cart: state.cateringOrder.items
  }),
  dispatch => ({
    addToCart: (item) => dispatch(createAction('cateringOrder.addItem', { item })),
    removeItemByCompositeId: (compositeId) => dispatch(createAction('cateringOrder.removeItemByCompositeId', { compositeId })),
    increaseQtyByCompositeId: (compositeId) => dispatch(createAction('cateringOrder.increaseQuantityByCompositeId', { compositeId })),
    decreaseQtyByCompositeId: (compositeId) => dispatch(createAction('cateringOrder.decreaseQuantityByCompositeId', { compositeId })),
    setQtyByCompositeId: (compositeId, quantity) => dispatch(createAction('cateringOrder.setQuantityByCompositeId', { compositeId, quantity }))
  })
)(withRouter(CateringItem))
