import React from 'react'

import { CateringPage, Section, withDependencies } from '../../containers'
import { CateringItem } from '../../components'
import BottomLinks from '../../components/BottomLinks'

class CateringDessertsPage extends React.Component {
  render () {
    const categories = this.props.catering.cateringCategories

    return (
      <CateringPage containerClasses='is-paddingless'>
        <Section>
          <h2 className='title is-size-4-mobile'>Desserts</h2>
          <p>Finish off your meal with a selection from our dessert menu.</p>
          {categories.DESS.map(item => <CateringItem item={item} key={item.id} descriptions={['25 pieces', '35 pieces', '45 pieces']} minimums={[1, 1, 1]} />)}
        </Section>
        <Section>
          <h2 className='title is-size-4-mobile'>Drinks</h2>
          <p>Our famous fresh squeezed juices can be a wonderful treat to your guests.</p>
          {categories.DRNK.map(item => <CateringItem item={item} key={item.id} mask={[true]} labels={['Per Serving']} descriptions={['or as specified']} minimums={[1, 1, 1]} />)}
        </Section>
        <Section>
          <h2 className='title is-size-4-mobile'>Servers & Wait Staff</h2>
          <p> You can count on us to provide trained wait staff and servers for your event. Our courteous personnel will attend to your guests and make sure the service goes smoothly. <strong>We require a minimum of two hours per server,</strong> additional hours may be entered below.</p>
          {categories.STFF.map(item => <CateringItem item={item} key={item.id} mask={[true, true]} labels={['Number of Servers', 'Total Hours']} descriptions={['', 'min 2 hrs per server']} minimums={[1, 2]} includeUnpriced />)}
        </Section>
        <BottomLinks />
      </CateringPage>
    )
  }
}

export default withDependencies(CateringDessertsPage, ['catering'], { includeProps: true })
