import { useEffect, useState } from 'react'

const milTime = Number(new Date().toLocaleString('en-us', { timeZone: 'America/Chicago', hour: 'numeric', hour12: false, minute: 'numeric' }).replace(':', ''))
const dayOfWeek = new Date().toLocaleString('en-us', { timeZone: 'America/Chicago', weekday: 'short' })
const isFriOrSat = ['Fri', 'Sat'].includes(dayOfWeek)

// normal
const isEarly = milTime < 1100
// new year's day
// const isEarly = milTime < 1200

// not christmas
const closingTimes = {
  Arlington: isFriOrSat ? 2050 : 2020,
  'Las Colinas': isFriOrSat ? 2120 : 2020,
  Richardson: isFriOrSat ? 2120 : 2020
}
const warningTimes = {
  Arlington: isFriOrSat ? 2020 : 1950,
  'Las Colinas': isFriOrSat ? 2050 : 1950,
  Richardson: isFriOrSat ? 2050 : 1950
}
// yes christmas
// const closingTimes = {
//   Arlington: 1900,
//   'Las Colinas': 1900,
//   Richardson: 1900
// }
// const warningTimes = {
//   Arlington: 1830,
//   'Las Colinas': 1830,
//   Richardson: 1830
// }

export const shouldWarn = (location) => (milTime >= warningTimes[location]) && closingTimes[location]
export const shouldSayClosed = (location) => isEarly || milTime >= closingTimes[location]

export const generatePickupTimes = (location) => {
  const lowPass = closingTimes[location]
  const highPass = shouldSayClosed(location) ? 1100 : (milTime + 20)
  const tomorrow = shouldSayClosed(location) && milTime > 1100

  // console.log(lowPass, highPass)

  const candidates = new Array(24).fill().map((value, hour) =>
    new Array(4).fill().map((value, quarter) =>
      ({ hour, minute: quarter * 15, mil: hour * 100 + quarter * 15 })
    )
  ).flat()

  const filtered = candidates.filter(candidate => candidate.mil >= highPass && candidate.mil < lowPass)

  return filtered.map(candidate => {
    const date = new Date()
    date.setDate(date.getDate() + 1)
    date.setHours(candidate.hour)
    date.setMinutes(candidate.minute)

    return date.toLocaleString('en-us', {
      hour: 'numeric',
      minute: 'numeric',
      weekday: tomorrow ? 'short' : undefined
    })
  })
}

export const usePickupTimes = (location) => {
  const [pickupTimes, setPickupTimes] = useState(generatePickupTimes(location))

  useEffect(() => {
    setPickupTimes(generatePickupTimes(location))

    const interval = setInterval(() => {
      setPickupTimes(generatePickupTimes(location))
    }, 30000)

    return () => clearInterval(interval)
  }, [location])

  return pickupTimes
}
