import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import { Section } from '../containers'

const BottomLinks = props => {
  const location = useLocation()
  const currentPath = location.pathname

  return (
    <Section>
      <div className='is-size-5 content'>
        <h2 className='has-text-weight-bold is-size-5'>Continue your order:</h2>
        <ul>
          {currentPath !== '/catering/lunchbox' && <li><Link to='/catering/lunchbox'>Lunchboxes</Link></li>}
          {currentPath !== '/catering/appetizers' && <li><Link to='/catering/appetizers'>Appetizers</Link></li>}
          {currentPath !== '/catering/salads' && <li><Link to='/catering/salads'>Dips&nbsp;&&nbsp;Salads</Link></li>}
          {currentPath !== '/catering/veggies' && <li><Link to='/catering/veggies'>Veggies&nbsp;&&nbsp;Rice</Link></li>}
          {currentPath !== '/catering/grill' && <li><Link to='/catering/grill'>Meat&nbsp;Specialties</Link></li>}
          {currentPath !== '/catering/desserts' && <li><Link to='/catering/desserts'>Desserts&nbsp;&&nbsp;Staff</Link></li>}
          <li><Link to='/catering/cart'>View Cart</Link></li>
        </ul>
      </div>
    </Section>
  )
}

export default BottomLinks
