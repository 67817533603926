import { detect } from 'detect-browser'

const browser = detect()

const loop = () => {
  // force body height to be actually body height
  document.body.style.height = `${window.innerHeight}px`

  window.requestAnimationFrame(loop)
}

if (browser.os === 'iOS') loop()
