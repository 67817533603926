import { useEffect } from 'react'
import { connect } from 'react-redux'

import createAction from '../actions'

const FormToState = props => {
  useEffect(() => {
    const fieldNames = ['fulfillment', 'date', 'time', 'firstname', 'lastname', 'email', 'phone', 'company', 'address1', 'address2', 'city', 'zip', 'comment']
    fieldNames.forEach((val, index) => {
      if (props[val] !== props.formValues[val]) {
        props[`${val}Update`](props.formValues[val])
      }
    })
  }, [props])

  return null
}

export default connect(
  state => ({
    fulfillment: state.cateringOrder.fulfillment,
    date: state.cateringOrder.date,
    time: state.cateringOrder.time,
    firstname: state.cateringOrder.firstname,
    lastname: state.cateringOrder.lastname,
    email: state.cateringOrder.email,
    phone: state.cateringOrder.phone,
    company: state.cateringOrder.company,
    address1: state.cateringOrder.address1,
    address2: state.cateringOrder.address2,
    city: state.cateringOrder.city,
    zip: state.cateringOrder.zip,
    comment: state.cateringOrder.comment
  }),
  dispatch => ({
    fulfillmentUpdate: (fulfillment) => dispatch(createAction('cateringOrder.fulfillment', { fulfillment })),
    dateUpdate: (date) => dispatch(createAction('cateringOrder.date', { date })),
    timeUpdate: (time) => dispatch(createAction('cateringOrder.time', { time })),
    firstnameUpdate: (firstname) => dispatch(createAction('cateringOrder.firstname', { firstname })),
    lastnameUpdate: (lastname) => dispatch(createAction('cateringOrder.lastname', { lastname })),
    emailUpdate: (email) => dispatch(createAction('cateringOrder.email', { email })),
    phoneUpdate: (phone) => dispatch(createAction('cateringOrder.phone', { phone })),
    companyUpdate: (company) => dispatch(createAction('cateringOrder.company', { company })),
    address1Update: (address1) => dispatch(createAction('cateringOrder.address1', { address1 })),
    address2Update: (address2) => dispatch(createAction('cateringOrder.address2', { address2 })),
    cityUpdate: (city) => dispatch(createAction('cateringOrder.city', { city })),
    zipUpdate: (zip) => dispatch(createAction('cateringOrder.zip', { zip })),
    commentUpdate: (comment) => dispatch(createAction('cateringOrder.comment', { comment }))
  })
)(FormToState)
