import React, { Component } from 'react'
import { CateringPage, Level, withDependencies } from '../../containers'
import { connect } from 'react-redux'

import createAction from '../../actions'
// import config from '../../config'
import NumberInput from '../../components/NumberInput'
import CateringOrderFrom from '../../forms/CateringOrderForm'

class cateringCart extends Component {
  constructor (props) {
    super(props)
    this.state = {
      checkoutLoading: false,
      isTipCustom: (this.props.tipFormat === 'custom'),
      tipAmount: this.props.tip.toFixed(2)
    }
  }

  // componentDidUpdate () {
  //   if (this.state.tipAmount !== this.props.tip.toFixed(2)) {
  //     this.setState({ tipAmount: this.props.tip.toFixed(2) })
  //   }
  // }

  handleTipBlur = val => {
    console.log(val)
    const tipAmount = isNaN(val) || val === '' ? '0.00' : parseFloat(val).toFixed(2)
    this.props.updateTip(tipAmount)
    this.setState({ tipAmount })
  }

  handleTipChange = ev => this.setState({ tipAmount: ev.target.value })

  handleTipAmount = val => {
    if (val === 'custom') {
      this.setState({ isTipCustom: true })
    } else {
      const tipValue = val === '' ? 0 : val
      const tipAmount = parseFloat((this.props.derived.subtotal / 100 * tipValue).toFixed(2))
      this.props.updateTip(tipAmount)
      this.setState({ tipAmount: parseFloat(tipAmount).toFixed(2), isTipCustom: false })
    }
    this.props.updateTipFormat(val)
  }

  render () {
    const items = this.props.items.map(item => {
      let description = item.description || ''
      if (item.type === 'lunchbox') {
        description = Object.keys(item.items).map(selectionKind => item.items[selectionKind].name).join('\n')
      }
      return {
        name: item.name,
        quantity: item.quantity,
        description: description,
        price: (item.price * item.quantity).toFixed(2),
        plusEnabled: item.plusEnabled,
        minusEnabled: item.minusEnabled,
        readOnly: item.readOnly
      }
    })
    const deliveryItemRaw = this.props.catering.byCode.XCHG_DLVY
    const deliveryItem = {
      id: deliveryItemRaw.id,
      name: deliveryItemRaw.name,
      type: 'item',
      category: deliveryItemRaw.category,
      code: deliveryItemRaw.code,
      price: deliveryItemRaw.tier1,
      quantity: 1,
      minimum: false,
      compositeId: deliveryItemRaw.id * 10,
      tier: 1,
      description: '',
      plusEnabled: false,
      minusEnabled: false,
      readOnly: true
    }

    let cartMainContent = (
      <div className='columns'>
        <div className='column'>
          <h2 className='title is-3 has-medium-top-margin is-size-4-mobile'>Your Order</h2>
          <Level classes='order-preview__header-row is-mobile' left='Item' right='Qty' leftClasses='is-size-5-mobile' rightClasses='is-size-5-mobile' />
          {
            items.map((item, index) => (
              <div className='order-preview__item catering-preview' key={index}>
                <Level
                  classes='order-preview__item-title-row is-mobile' leftClasses='order-preview__item-title is-size-6-mobile' left={
                    item.name
                  } rightClasses='order-preview__item-quantity' right={
                    <NumberInput
                      value={Number(item.quantity)}
                      onChange={event => this.props.setQuantity(index, event.target.value)}
                      onIncrease={() => this.props.increaseQuantity(index)}
                      onDecrease={() => this.props.decreaseQuantity(index)}
                      minusDisabled={!item.minusEnabled}
                      plusDisabled={!item.plusEnabled}
                      readOnly={item.readOnly}
                      small
                    />
                  }
                />
                <div className='order-preview__item-description'>{item.description}</div>
                <div className='order-preview__item-price'>{item.price}</div>
              </div>
            ))
          }
        </div>
      </div>
    )

    const tipContent = (
      <div className='field has-addons'>
        <div className='control'>
          <div className='select is-small'>
            <select onChange={ev => this.handleTipAmount(ev.target.value)} value={this.props.tipFormat}>
              <option value=''>Tip</option>
              <option value='10'>10%</option>
              <option value='15'>15%</option>
              <option value='20'>20%</option>
              <option value='custom'>Custom</option>
            </select>
          </div>
        </div>
        <div className='control catering-tip-input'>
          <input type='number' value={this.state.tipAmount} className='input is-small has-text-right tip-amount' readOnly={!this.state.isTipCustom} onChange={ev => this.handleTipChange(ev)} onBlur={ev => this.handleTipBlur(ev.target.value)} placeholder='Tip Amount' onFocus={ev => ev.target.select()} />
        </div>
      </div>
    )

    let cartFooter = (
      <>
        <div className='order-preview__foot'>
          <Level classes='order-preview__tax-row is-mobile' left='Subtotal' right={`${this.props.derived.subtotal.toFixed(2)}`} /><br />
          <Level classes='order-preview__tax-row is-mobile' left='Sales Tax' right={`${this.props.derived.tax.toFixed(2)}`} /><br />
          <Level classes='order-preview__tax-row is-mobile' left='Tip' right={tipContent} /><br />
          <Level classes='order-preview__total-row is-mobile' left='Total' right={`$${this.props.derived.total.toFixed(2)}`} leftClasses='is-size-5-mobile' rightClasses='is-size-5-mobile' />
        </div>
      </>
    )

    if (items.length === 0) {
      // cartTopButton = ''
      cartMainContent = (
        <div className='columns'>
          <div className='column'>
            <h2 className='title is-3 has-medium-top-margin is-size-4-mobile'>Your Order</h2>
            <p>Your cart is empty</p>
          </div>
        </div>
      )
      cartFooter = ''
    }

    return (
      <CateringPage containerClasses='is-starting-top is-paddingless catering-cart-wrapper'>
        <div className='columns'>
          <div className='column'>
            <h2 className='title is-3 has-medium-top-margin is-size-4-mobile'>Your Pick up / Delivery Information</h2>
            <p>Please provide us with details about your order.</p>
            <p className='has-text-weight-semibold'>For rush orders, within 12 hours, place your order here then call the restaurant location at phone number below. Let the manager know you've placed a short notice order, this will help ensure your order is given priority.</p>
            <p className='has-text-weight-semibold'>Chef's service & staff require a minimum 2 day notice.</p>
            <p>Las Colinas - 972.401.8900<br />Arlington - 817.465.2100<br />Richardson - 972.907.3000</p>
          </div>
        </div>
        <div className='columns'>
          <div className='column'>
            <CateringOrderFrom
              transferred={{
                updateLocation: this.props.updateLocation,
                updateTip: this.props.updateTip,
                updateTipFormat: this.props.updateTipFormat,
                addToCart: this.props.addToCart,
                removeItem: this.props.removeItem,
                location: this.props.location,
                items: this.props.items,
                locations: this.props.locations,
                tip: this.props.tip,
                fulfillment: this.props.fulfillment,
                date: this.props.date,
                time: this.props.time,
                firstname: this.props.firstname,
                lastname: this.props.lastname,
                email: this.props.email,
                phone: this.props.phone,
                company: this.props.company,
                address1: this.props.address1,
                address2: this.props.address2,
                city: this.props.city,
                zip: this.props.zip,
                comment: this.props.comment,
                clearOrder: this.props.clearOrder,
                deliveryItem
              }}
            >
              {cartMainContent}
              {cartFooter}
              <h2 className='title is-3 has-medium-top-margin is-size-4-mobile'>Your Contact Information</h2>
            </CateringOrderFrom>
          </div>
        </div>
      </CateringPage>
    )
  }
}

export default connect(
  state => ({
    items: state.cateringOrder.items,
    location: state.cateringOrder.location,
    tip: state.cateringOrder.tip,
    tipFormat: state.cateringOrder.tipFormat,
    derived: state.cateringOrder.derived,
    fulfillment: state.cateringOrder.fulfillment,
    date: state.cateringOrder.date,
    time: state.cateringOrder.time,
    firstname: state.cateringOrder.firstname,
    lastname: state.cateringOrder.lastname,
    email: state.cateringOrder.email,
    phone: state.cateringOrder.phone,
    company: state.cateringOrder.company,
    address1: state.cateringOrder.address1,
    address2: state.cateringOrder.address2,
    city: state.cateringOrder.city,
    zip: state.cateringOrder.zip,
    comment: state.cateringOrder.comment
  }),
  dispatch => ({
    addToCart: (item) => dispatch(createAction('cateringOrder.addItem', { item })),
    increaseQuantity: (index) => dispatch(createAction('cateringOrder.increaseQuantity', { index })),
    decreaseQuantity: (index) => dispatch(createAction('cateringOrder.decreaseQuantity', { index })),
    setQuantity: (index, quantity) => dispatch(createAction('cateringOrder.setQuantity', { index, quantity })),
    showDebug: (output) => dispatch(createAction('modal.open', { name: 'debug', data: { output } })),
    clearOrder: (output) => dispatch(createAction('cateringOrder.clear')),
    updateLocation: (location) => dispatch(createAction('cateringOrder.location', { location })),
    removeItem: (itemId) => dispatch(createAction('cateringOrder.removeItem', { itemId })),
    updateTip: (tip) => dispatch(createAction('cateringOrder.tip', { tip })),
    updateTipFormat: (tipFormat) => dispatch(createAction('cateringOrder.tipFormat', { tipFormat }))
  })
)(withDependencies(cateringCart, ['locations', 'catering'], { includeProps: true }))
