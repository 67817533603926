import config from '../config'

import createAction from '.'

const hourOfDay = Number(new Date().toLocaleString('en-us', { timeZone: 'America/Chicago', hour: 'numeric', hour12: false }))
const dayOfWeek = new Date().toLocaleString('en-us', { timeZone: 'America/Chicago', weekday: 'short' })
const isWeekday = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'].includes(dayOfWeek)
const isLunchHour = isWeekday && hourOfDay >= 11 && hourOfDay < 14

export function loadMenu () {
  return async (dispatch, getState) => {
    const result = await window.fetch(new window.URL('/ordering/items', config.apiUrl).href)
    const list = await result.json()

    const createDisplayMenu = (list) => {
      const categories = list.categories.map(category => {
        const result = {
          name: category.name,
          items: list.foods
            .filter(food => food.categoryId === category.id)
            .map(food => {
              if (food.type === 'platter') {
                if (!food.menuActive || food.price == null) return null
                const currentPrice = isLunchHour && food.config.lunchHourPrice ? food.config.lunchHourPrice : food.price

                return [{
                  ...food,
                  linkId: food.id,
                  price: currentPrice
                }]
              }

              if (food.type === 'simple') {
                const items = list.items
                  .filter(item => item.foodId === food.id)
                  .filter(item => item.menuActive && item.price != null)

                const convertItem = item => ({
                  type: 'item',
                  linkId: food.id,
                  id: item.id,
                  name: item.name,
                  description: food.description,
                  image: (item.image || food.image).replace(/[^\w \-_/:.]/g, '').replace(/ /g, '+'),
                  price: item.price,
                  category: category.name,
                  config: food.config
                })

                if (items.length <= 1) {
                  return items.map(convertItem)
                }

                // multiple selections
                return [{
                  type: 'container',
                  linkId: food.id,
                  name: food.name,
                  description: food.description,
                  image: food.image.replace(/[^\w \-_/:.]/g, '').replace(/ /g, '+'),
                  price: null,
                  category: category.name,
                  config: food.config,
                  items: items.map(convertItem).sort((a, b) => a.name.localeCompare(b.name))
                }]
              }

              return null
            })
            .flat()
            .filter(value => value != null)
            .sort((a, b) => a.name.localeCompare(b.name))
        }
        return result
      })

      return categories.filter(category => category.items.length)
    }

    const createPlatterMenu = (list) => {
      const foodsById = {}
      list.foods.forEach(food => (foodsById[food.id] = food))

      const categories = list.categories.map(category => {
        return {
          id: category.id,
          items: list.items.filter(item => {
            const food = foodsById[item.foodId]
            if (!food) return false
            if (food.categoryId !== category.id) return false

            if (!item.platterActive) return false

            return true
          }).map(item => {
            const food = foodsById[item.foodId]

            return {
              ...item,
              image: item.image || food.image,
              disabledAtRichardson: Boolean(food.config.disabledAtRichardson)
            }
          })
        }
      }).reduce((acc, { id, items }) => {
        acc[id] = items
        return acc
      }, {})

      return categories
    }

    const displayMenu = createDisplayMenu(list)
    const platterMenu = createPlatterMenu(list)

    const linkingMenu = displayMenu
      .map(category => category.items)
      .flat()
      .reduce((acc, item) => {
        acc[item.linkId] = item
        return acc
      }, {})

    dispatch(createAction('loaders.load', {
      name: 'menu',
      value: {
        ...list,
        displayMenu,
        platterMenu,
        linkingMenu
      }
    }))
  }
}

export function loadLocations () {
  return async (dispatch, getState) => {
    const result = await window.fetch(new window.URL('/ordering/locations', config.apiUrl).href)
    const list = await result.json()

    dispatch(createAction('loaders.load', { name: 'locations', value: list }))
  }
}

export function loadCatering () {
  return async (dispatch, getState) => {
    const result = await window.fetch(new window.URL('/catering/items', config.apiUrl).href)
    const list = await result.json()

    const fixedList = list.map(item => {
      return {
        ...item,
        name: item.name.replace(/&amp;/g, '&').replace(/&#39;/g, "'")
      }
    })

    const cateringItems = fixedList.filter(item => item.cateringEnabled)
    const lunchboxItems = fixedList.filter(item => item.lunchboxEnabled) // TODO fix this

    const cateringCategories = {}
    const lunchboxCategories = {}

    cateringItems.forEach(item => {
      if (!cateringCategories[item.category]) cateringCategories[item.category] = []
      cateringCategories[item.category].push(item)
    })

    lunchboxItems.forEach(item => {
      if (!lunchboxCategories[item.category]) lunchboxCategories[item.category] = []
      lunchboxCategories[item.category].push(item)
    })

    const byCode = {}
    fixedList.forEach(item => (byCode[item.code] = item))

    dispatch(createAction('loaders.load', {
      name: 'catering',
      value: {
        items: list,
        cateringItems,
        lunchboxItems,
        cateringCategories,
        lunchboxCategories,
        byCode
      }
    }))
  }
}
