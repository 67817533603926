const initialState = {
  platter: null,
  selections: [],
  currentIndex: 0
}

const arrayOfArrays = length => new Array(length).fill().map(() => [])

const platterReducer = (state = initialState, action) => {
  const type = action.type // eslint-disable-line no-unused-vars

  if (type === 'platter.set') return { ...state, platter: action.platter, selections: arrayOfArrays(action.platter.config.items.length), currentIndex: 0 }
  if (type === 'platter.clear') return { ...state, platter: null }

  if (type === 'platter.back') return { ...state, currentIndex: Math.max(state.currentIndex - 1, 0) }
  if (type === 'platter.next') return { ...state, currentIndex: state.currentIndex + 1 }

  if (type === 'platter.select') {
    const currentSelection = state.selections[state.currentIndex]
    const maxSelections = state.platter.config.items[state.currentIndex].quantity

    const singleSelection = maxSelections === 1

    const selections = state.selections.slice(0)
    selections[state.currentIndex] = singleSelection ? [action.item] : currentSelection.concat([action.item]) // .slice(-maxSelections)

    return { ...state, selections }
  }

  if (type === 'platter.deselect') {
    const currentSelection = state.selections[state.currentIndex]

    const selections = state.selections.slice(0)
    selections[state.currentIndex] = currentSelection.filter(item => item !== action.item)

    return { ...state, selections }
  }

  if (type === 'platter.deselect-one') {
    const currentSelection = state.selections[state.currentIndex]

    const selections = state.selections.slice(0)
    const itemIndex = selections[state.currentIndex].indexOf(action.item)
    selections[state.currentIndex] = currentSelection.filter((item, index) => index !== itemIndex)

    return { ...state, selections }
  }

  return state
}

export default platterReducer
