import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { CateringPage, Container } from '../../containers'

class CateringHomePage extends React.Component {
  render () {
    return (
      <CateringPage>
        <Container>
          <div className='columns catering-home-page__columns'>
            <div className='column'>
              <img src='https://www.andalousgrill.com/image-uploads-manual/catering-andalous-maj-noon-chicken.jpg' alt='' />
            </div>
            <div className='column'>
              <h1 className='title'>Catering for Large Groups and Events</h1>
              <div className='is-size-5 content'>
                <h2 className='has-text-weight-bold is-size-5'>Start Your Catering Order:</h2>
                <ul>
                  <li><Link to='/catering/lunchbox'>Lunchboxes</Link></li>
                  <li><Link to='/catering/appetizers'>Appetizers</Link></li>
                  <li><Link to='/catering/salads'>Dips&nbsp;&&nbsp;Salads</Link></li>
                  <li><Link to='/catering/veggies'>Veggies&nbsp;&&nbsp;Rice</Link></li>
                  <li><Link to='/catering/grill'>Meat&nbsp;Specialties</Link></li>
                </ul>
              </div>
              <p>Surprise your guests at your next event with our mouth-watering Mediterranean cuisine. We offer full catering, lunch boxes and wait staff to make your event's meal a memorable one. We pride ourselves in using only the freshest ingredients from our traditional family recipes.</p>
              <p>From office luncheons, business meetings, corporate catering, to large family celebrations and weddings you can count on our experienced staff. We can help plan your event and offer suggestions for any type of occasion.</p>
              <p>We do our best to provide quality, reliable service on short notice. For rush orders, within 12 hours, place your order here then call the restaurant location at phone number below. Let the manager know you've placed a short notice order, this will help ensure your order is given priority.</p>
              <p>Chef's service & staff require a minimum 2 day notice.</p>
              <p>Thank you for allowing us the opportunity to serve your catering needs.</p>
            </div>
          </div>
        </Container>
      </CateringPage>
    )
  }
}

export default connect(

)(CateringHomePage)
