import React from 'react'
import { connect } from 'react-redux'

import createAction from '../../actions'
import { CateringPage, Section, withDependencies } from '../../containers'
import { NumberInputManaged } from '../../components'
import BottomLinks from '../../components/BottomLinks'

class CateringLunchboxPage extends React.PureComponent {
  categories = this.props.catering.lunchboxCategories

  sandwiches = this.categories.LBSD
  salads = this.categories.SALD
  dips = this.categories.DIPS
  desserts = this.categories.DESS
  entrees = this.categories.LBMT
  rice = this.categories.RICE

  sides = [].concat(this.categories.SALD, this.categories.DIPS, this.categories.RICE)

  selectChefs = list => (list.filter(item => item.code.slice(-4) === '1CHF')[0] || { id: -1 }).id

  selectFood = (collectionId, elementId) => {
    const { id, category, code, name } = this[collectionId].filter(item => item.id === Number(elementId))[0]
    return { id, category, code, name }
  }

  orderItems = {
    lunchboxSandwich: {
      id: this.props.catering.byCode.LBOX_SAND.id,
      name: 'Sandwich Lunch Box',
      type: 'lunchbox',
      category: 'LBOX',
      code: 'LBOX_SAND',
      items: {
        sandwich: {},
        salad: {},
        dip: {}
      },
      quantity: 1,
      price: this.props.catering.byCode.LBOX_SAND.tier1.toFixed(2),
      compositeId: 0,
      plusEnabled: true,
      minusEnabled: true,
      readOnly: false
    },
    lunchboxExecutive: {
      id: this.props.catering.byCode.LBOX_EXEC.id,
      name: 'Executive Lunch Box',
      type: 'lunchbox',
      category: 'LBOX',
      code: 'LBOX_EXEC',
      items: {
        entree: {},
        side1: {},
        side2: {},
        side3: {},
        dessert: {}
      },
      quantity: 1,
      price: this.props.catering.byCode.LBOX_EXEC.tier1.toFixed(2),
      compositeId: 0,
      plusEnabled: true,
      minusEnabled: true,
      readOnly: false
    }
  }

  orderItemsInitialContents = {
    lunchboxSandwich: [
      this.selectFood('sandwiches', this.selectChefs(this.sandwiches)),
      this.selectFood('salads', this.selectChefs(this.salads)),
      this.selectFood('dips', this.selectChefs(this.dips))
    ],
    lunchboxExecutive: [
      this.selectFood('entrees', this.selectChefs(this.entrees)),
      this.selectFood('salads', this.selectChefs(this.salads)),
      this.selectFood('dips', this.selectChefs(this.dips)),
      this.selectFood('rice', this.selectChefs(this.rice)),
      this.selectFood('desserts', this.selectChefs(this.desserts))
    ]
  }

  initialState = {
    lunchboxSandwichsandwich: this.orderItemsInitialContents.lunchboxSandwich[0],
    lunchboxSandwichsalad: this.orderItemsInitialContents.lunchboxSandwich[1],
    lunchboxSandwichdip: this.orderItemsInitialContents.lunchboxSandwich[2],
    lunchboxSandwichquantity: 1,
    lunchboxExecutiveentree: this.orderItemsInitialContents.lunchboxExecutive[0],
    lunchboxExecutiveside1: this.orderItemsInitialContents.lunchboxExecutive[1],
    lunchboxExecutiveside2: this.orderItemsInitialContents.lunchboxExecutive[2],
    lunchboxExecutiveside3: this.orderItemsInitialContents.lunchboxExecutive[3],
    lunchboxExecutivedessert: this.orderItemsInitialContents.lunchboxExecutive[4],
    lunchboxExecutivequantity: 1
  }

  state = this.initialState

  componentDidMount () {
    this.updateOrderItem('lunchboxSandwich', 1, this.orderItemsInitialContents.lunchboxSandwich)
    this.updateOrderItem('lunchboxExecutive', 1, this.orderItemsInitialContents.lunchboxExecutive)
  }

  updateOrderItem = (itemName, itemQty, itemContents) => {
    this.orderItems[itemName].quantity = itemQty
    const orderItemContentKeys = Object.keys(this.orderItems[itemName].items)
    orderItemContentKeys.forEach((contentKey, index) => {
      this.orderItems[itemName].items[contentKey] = itemContents[index]
    })
    this.orderItems[itemName].compositeId = this.createCompositeId(this.orderItems[itemName])
  }

  handleFoodValueChange = (boxType, foodKind, collectionId, elementId) => {
    const statePropName = boxType + foodKind
    if (boxType === 'lunchboxSandwich') {
      const lunchboxSandwich = this.orderItems.lunchboxSandwich
      lunchboxSandwich.items[foodKind] = this.selectFood(collectionId, elementId)
      lunchboxSandwich.compositeId = this.createCompositeId(lunchboxSandwich)
      this.setState({ [statePropName]: lunchboxSandwich.items[foodKind] })
    } else {
      const lunchboxExecutive = this.orderItems.lunchboxExecutive
      lunchboxExecutive.items[foodKind] = this.selectFood(collectionId, elementId)
      lunchboxExecutive.compositeId = this.createCompositeId(lunchboxExecutive)
      this.setState({ [statePropName]: lunchboxExecutive.items[foodKind] })
    }
  }

  handleQuantityChange = ev => {
    if (ev.boxType === 'lunchboxSandwich') {
      this.orderItems.lunchboxSandwich.quantity = ev.target.value
      this.setState({ lunchboxSandwichquantity: ev.target.value })
    } else {
      this.orderItems.lunchboxExecutive.quantity = ev.target.value
      this.setState({ lunchboxExecutivequantity: ev.target.value })
    }
  }

  handleSaveToCart = lunchboxName => {
    const selectedLunchbox = this.orderItems[lunchboxName]
    this.props.saveToCart(selectedLunchbox)
    this.setState({ ...this.initialState })
  }

  createCompositeId = lunchbox => {
    return (lunchbox.id + Object.keys(lunchbox.items).reduce((acc, cur) => { return acc + lunchbox.items[cur].id }, 0)) * 1000
  }

  render () {
    return (
      <CateringPage containerClasses='is-paddingless'>
        <Section>
          <h2 className='title is-size-4-mobile'>Sandwich Lunch Box - {this.orderItems.lunchboxSandwich.price} ea</h2>
          <p>Our standard lunch box provides an honest, well-rounded meal for each person in your group. Select a sandwich and two sides; a serving of baklava is included as dessert! Use the controls below to create your lunch box as your group has requested or simply leave the decisions up to our chef. Create as many different lunch boxes as necessary.</p>

          <div className='catering-lunchbox-page__form'>
            <div className='field is-horizontal'>
              <div className='field-label is-normal'>
                <label className='label'>Select a sandwich</label>
              </div>
              <div className='field-body'>
                <div className='field'>
                  <div className='control'>
                    <div className='select'>
                      <select onChange={e => this.handleFoodValueChange('lunchboxSandwich', 'sandwich', 'sandwiches', e.target.value)} value={this.state.lunchboxSandwichsandwich.id}>
                        {this.sandwiches.map(item => <option value={item.id} key={item.id}>{item.name}</option>)}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='field is-horizontal'>
              <div className='field-label is-normal'>
                <label className='label'>Select a salad</label>
              </div>
              <div className='field-body'>
                <div className='field'>
                  <div className='control'>
                    <div className='select'>
                      <select onChange={e => this.handleFoodValueChange('lunchboxSandwich', 'salad', 'salads', e.target.value)} value={this.state.lunchboxSandwichsalad.id}>
                        {this.salads.map(item => <option value={item.id} key={item.id}>{item.name}</option>)}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='field is-horizontal'>
              <div className='field-label is-normal'>
                <label className='label'>Select a dip</label>
              </div>
              <div className='field-body'>
                <div className='field'>
                  <div className='control'>
                    <div className='select'>
                      <select onChange={e => this.handleFoodValueChange('lunchboxSandwich', 'dip', 'dips', e.target.value)} value={this.state.lunchboxSandwichdip.id}>
                        {this.dips.map(item => <option value={item.id} key={item.id}>{item.name}</option>)}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='field is-horizontal'>
              <div className='field-label is-normal'>
                <label className='label'>Quantity</label>
              </div>
              <div className='field-body'>
                <NumberInputManaged onChange={this.handleQuantityChange} addToValue={{ boxType: 'lunchboxSandwich' }} minValue={1} maxValue={999} value={this.state.lunchboxSandwichquantity} />
              </div>
            </div>

            <div className='field is-horizontal'>
              <div className='field-label is-normal' />
              <div className='field-body'>
                <div className='field'>
                  <div className='control'>
                    <button className='button is-primary catering-lunchbox-page__cart-button' onClick={() => this.handleSaveToCart('lunchboxSandwich')}>Save to Cart</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <Section>
          <h2 className='title is-size-4-mobile'>Executive Lunch Box - {this.orderItems.lunchboxExecutive.price} ea</h2>
          <p>Our executive lunch box provides all the choices of our dine-in experience. You may configure each lunch box with any combination of salads, dips or rices. Finish off your meal with our delicious baklava.</p>

          <div className='catering-lunchbox-page__form'>
            <div className='field is-horizontal'>
              <div className='field-label is-normal'>
                <label className='label'>Select an entrée</label>
              </div>
              <div className='field-body'>
                <div className='field'>
                  <div className='control'>
                    <div className='select'>
                      <select value={this.state.lunchboxExecutiveentree.id} onChange={e => this.handleFoodValueChange('lunchboxExecutive', 'entree', 'entrees', e.target.value)}>
                        {this.entrees.map(item => <option value={item.id} key={item.id}>{item.name}</option>)}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='field is-horizontal'>
              <div className='field-label is-normal'>
                <label className='label'>Select side #1</label>
              </div>
              <div className='field-body'>
                <div className='field'>
                  <div className='control'>
                    <div className='select'>
                      <select value={this.state.lunchboxExecutiveside1.id} onChange={e => this.handleFoodValueChange('lunchboxExecutive', 'side1', 'sides', e.target.value)}>
                        {this.sides.map(item => <option value={item.id} key={item.id}>{item.name}</option>)}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='field is-horizontal'>
              <div className='field-label is-normal'>
                <label className='label'>Select side #2</label>
              </div>
              <div className='field-body'>
                <div className='field'>
                  <div className='control'>
                    <div className='select'>
                      <select value={this.state.lunchboxExecutiveside2.id} onChange={e => this.handleFoodValueChange('lunchboxExecutive', 'side2', 'sides', e.target.value)}>
                        {this.sides.map(item => <option value={item.id} key={item.id}>{item.name}</option>)}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='field is-horizontal'>
              <div className='field-label is-normal'>
                <label className='label'>Select side #3</label>
              </div>
              <div className='field-body'>
                <div className='field'>
                  <div className='control'>
                    <div className='select'>
                      <select value={this.state.lunchboxExecutiveside3.id} onChange={e => this.handleFoodValueChange('lunchboxExecutive', 'side3', 'sides', e.target.value)}>
                        {this.sides.map(item => <option value={item.id} key={item.id}>{item.name}</option>)}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='field is-horizontal'>
              <div className='field-label is-normal'>
                <label className='label'>Select a Dessert</label>
              </div>
              <div className='field-body'>
                <div className='field'>
                  <div className='control'>
                    <div className='select'>
                      <select value={this.state.lunchboxExecutivedessert.id} onChange={e => this.handleFoodValueChange('lunchboxExecutive', 'dessert', 'desserts', e.target.value)}>
                        {this.desserts.map(item => <option value={item.id} key={item.id}>{item.name}</option>)}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='field is-horizontal'>
              <div className='field-label is-normal'>
                <label className='label'>Quantity</label>
              </div>
              <div className='field-body'>
                <NumberInputManaged onChange={this.handleQuantityChange} addToValue={{ boxType: 'lunchboxExecutive' }} minValue={1} maxValue={999} value={this.state.lunchboxExecutivequantity} />
              </div>
            </div>

            <div className='field is-horizontal'>
              <div className='field-label is-normal' />
              <div className='field-body'>
                <div className='field'>
                  <div className='control'>
                    <button className='button is-primary catering-lunchbox-page__cart-button' onClick={() => this.handleSaveToCart('lunchboxExecutive')}>Save to Cart</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <BottomLinks />
      </CateringPage>
    )
  }
}

export default connect(
  null,
  dispatch => ({
    saveToCart: (lunchbox) => {
      dispatch(createAction('cateringOrder.addLunchBox', { lunchbox }))
    }
  })
)(withDependencies(CateringLunchboxPage, ['catering'], { includeProps: true }))
