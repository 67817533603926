import React from 'react'
import { connect } from 'react-redux'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { Link, withRouter } from 'react-router-dom'
import * as yup from 'yup'
import { Helmet } from 'react-helmet'

import createAction from '../actions'
import { loadMenu, loadLocations } from '../actions/loaders'
import { IconCard, OrderPreview, TitleBar, MenuItem, BlippedButton, useModal } from '../components'
import { Container, Hero, IconCards, OrderLayout, Section, withDependencies } from '../containers'
import { shouldSayClosed, shouldWarn } from '../utils/opening-hours'
import config from '../config'

const emailSchema = yup.string().email().min(1)
const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
const phoneSchema = yup.string().required('Phone number').matches(phoneRegExp, 'Enter a valid phone number').min(10, 'Enter a valid phone number').max(20, 'Enter a valid phone number')

class HomePage extends React.Component {
  constructor () {
    super()

    this.state = {
      name: '',
      email: '',
      phone: '',
      emailValid: false,
      emailTouched: false,
      phoneValid: false,
      phoneTouched: false,
      locationIndex: 0,
      deepLink: null
    }

    const formatPhoneValue = (val) => {
      if (val.length < 10) return val
      const eliminationRegExp = /[\s()-]/gi
      const cleansedVal = val.replace(eliminationRegExp, '')
      const formattedVal = ['(', cleansedVal.slice(0, 3), ')', ' ', cleansedVal.slice(3, 6), '-', cleansedVal.slice(6)].join('')
      return formattedVal
    }

    this.handleNameChange = event => this.setState({ name: event.target.value })
    this.handleEmailChange = event => this.setState({ email: event.target.value, emailValid: emailSchema.isValidSync(event.target.value) })
    this.handlePhoneChange = event => this.setState({ phone: formatPhoneValue(event.target.value), phoneValid: phoneSchema.isValidSync(formatPhoneValue(event.target.value)) })
    this.handleEmailTouch = event => this.setState({ emailTouched: true })
    this.handlePhoneTouch = event => this.setState({ phoneTouched: true })
    this.handleLocationChange = event => this.setState({ locationIndex: Number(event.target.value) })
    this.handleCommitParams = async () => {
      const name = this.state.name
      const location = this.props.locations[this.state.locationIndex]
      const email = this.state.email
      const phone = this.state.phone

      const closureSettings = await (await window.fetch(new URL('/ordering/config/close', config.apiUrl).href)).json()

      let restaurantClosed = false
      let restaurantClosedError = ''

      if (closureSettings.ok) {
        const { start, end } = closureSettings.value

        const startDate = new Date(start.replace(/-/g, '/'))
        const endDate = new Date(end.replace(/-/g, '/'))

        endDate.setDate(endDate.getDate() + 1)
        // if (!pickupTimes.includes('ASAP') && new Date().getHours() < 12) startDate.setDate(startDate.getDate() - 1)
        // //                                                         ^ ugly hack right there
        // said ugly hack was about closing the restaurant the day before, we'll manage here without it

        const now = new Date().getTime()
        if (now > startDate.getTime() && now < endDate.getTime()) {
          restaurantClosed = true
          restaurantClosedError = `Restaurants are closed until ${endDate.toLocaleString('en-us', { weekday: 'long', month: 'long', day: 'numeric' })}`
        }
      }

      if (restaurantClosed) {
        await this.props.showModal('Kitchen Closed', restaurantClosedError)
        return
      }

      if (shouldSayClosed(location.name)) {
        await this.props.showModal('Kitchen Closed', `Our ${location.name} kitchen is currently closed. You may order ahead and select a pickup time at checkout.`)
      } else if (shouldWarn(location.name)) {
        const rawMilTime = shouldWarn(location.name)
        const milTime = String(rawMilTime)

        const date = new Date()
        date.setHours(milTime.slice(0, 2))
        date.setMinutes(milTime.slice(2, 4))
        const readableTime = date.toLocaleString('en-us', { hour: 'numeric', minute: 'numeric' })

        await this.props.showModal('Kitchen Closing Soon', `Our ${location.name} kitchen is closing at ${readableTime}. Orders placed after closing must select a pickup time at checkout.`)
      }

      this.props.commitParams(name, location, email, phone)
    }

    this.handleEditParams = () => {
      this.setState({
        name: this.props.name,
        location: this.props.locations.indexOf(this.props.location),
        email: this.props.email,
        emailValid: emailSchema.isValidSync(this.props.email),
        phone: formatPhoneValue(this.props.phone),
        phoneValid: phoneSchema.isValidSync(formatPhoneValue(this.props.phone))
      })
      this.props.commitParams('', null, '', '')
    }

    this.handleDeepLink = () => {
      if (!this.state.deepLink) return

      const { name, location } = this.props
      if (!name && !location) return

      if (this.state.deepLink.config.disabledAtRichardson && location.name === 'Richardson') {
        this.setState({ deepLink: null })
        return
      }

      this.props.openMenuModal(this.state.deepLink)
      this.setState({ deepLink: null })
    }
  }

  componentDidMount () {
    this.props.loadDependenices()

    const locationPreset = this.props.match.params.location
    if (locationPreset) {
      const locationMap = {}
      this.props.locations.forEach((loc, index) => (locationMap[loc.name.toLowerCase().replace(/[ _-]/g, ' ')] = index))

      const locationKey = locationPreset.toLowerCase().replace(/[ _-]/g, ' ')
      this.setState({ locationIndex: locationMap[locationKey] })

      this.props.history.push('/')
    }

    const foodPreset = this.props.match.params.food
    if (foodPreset) {
      const linkingMenu = this.props.menu.linkingMenu || {}
      const food = linkingMenu[foodPreset]

      this.setState({ deepLink: food })
      this.props.history.push('/')
      this.handleDeepLink()
    }
  }

  componentDidUpdate () {
    if (this.state.deepLink) this.handleDeepLink()
  }

  render () {
    const menu = this.props.menu.displayMenu
    const { name, location, email, phone } = this.props
    const currentYear = new Date().getFullYear()

    // console.log(this.state.locationIndex)

    if (!name || !location || !email || !phone) {
      return (
        <Hero
          classes='is-fullheight is-mobile'
          head={
            <TitleBar>
              Online Ordering
            </TitleBar>
          }
          footClasses='catering-page__foot'
          foot={
            <>
              © {currentYear} Andalous Mediterranean Grill, LLC, All&nbsp;Rights&nbsp;Reserved
              <br />
              The Andalous Logo is a registered trademark of Andalous Mediterranean Grill, LLC
              <br />
              Copying or duplication of images contained herein is strictly forbidden without the prior written consent of Andalous Mediterranean Grill, LLC
              <br />
              <Link to='/privacy-policy'>Privacy Policy</Link> | <Link to='/terms-of-service'>Terms of Service</Link>
            </>
          }
        >
          <Helmet>
            <title>Online Ordering | Andalous Mediterranean Grill</title>
            <meta property='og:title' content='Andalous Mediterranean Grill Online Ordering' />
            <meta property='og:type' content='article' />
            <meta property='og:description' content='Andalous Mediterranean Grill offers a delicious variety of dips, salads, veggies, protiens and desserts. Use our online ordering system for fast to-go orders.' />
            <meta property='og:url' content='https://order.andalousgrill.com/' />
            <meta property='og:image' content='https://andalous-website.s3.us-east-2.amazonaws.com/image-uploads-manual/Andalous-OG-Online-Orders.png' />
          </Helmet>
          <Container classes='home-page__basic-form'>
            <div className='field'>
              <label className='label is-medium'>Enter your name:</label>
              <div className='control'>
                <input className='input' type='text' placeholder='First & Last Name' value={this.state.name} onChange={this.handleNameChange} />
              </div>
            </div>
            <div className='field'>
              <label className='label is-medium'>Enter your email address:</label>
              <div className='control'>
                <input className='input' type='email' placeholder='jane.doe@example.com' value={this.state.email} onChange={this.handleEmailChange} onBlur={this.handleEmailTouch} />
              </div>
              <p className='help is-danger'>
                {
                  (this.state.emailTouched && !this.state.emailValid) ? 'Error: invalid email' : ''
                }
              </p>
            </div>
            <div className='field'>
              <label className='label is-medium'>Enter your phone number:</label>
              <div className='control'>
                <input className='input' type='text' placeholder='Phone Number' value={this.state.phone} onChange={this.handlePhoneChange} onBlur={this.handlePhoneTouch} />
              </div>
              <p className='help is-danger'>
                {
                  (this.state.phoneTouched && !this.state.phoneValid) ? 'Error: invalid phone number' : ''
                }
              </p>
            </div>
            <div className='field'>
              <label className='label is-medium'>Pick a restaurant:</label>
              <div className='control home-page__select-field'>
                <div className='select'>
                  <select value={this.state.locationIndex} onChange={this.handleLocationChange}>
                    {this.props.locations.map((location, index) => <option key={location.id} value={index}>{location.name}</option>)}
                  </select>
                </div>
                <input type='button' value='Continue' className='button is-primary' onClick={this.handleCommitParams} onMouseDown={this.handleEmailTouch} disabled={!this.state.emailValid || !this.state.locationIndex} />
              </div>
            </div>
          </Container>
        </Hero>
      )
    }

    const filteredCategories = menu
      .map(category => ({
        ...category,
        items: category.items.filter(item => {
          if (location.name === 'Richardson' && item.config.disabledAtRichardson) return false // HARDCODED

          return true
        })
      }))
      .filter(category => category.items.length)

    return (
      <OrderLayout
        title={
          <>
            Pick up for {name} at {location.name}
            <BlippedButton icon={faPen} classes='home-page__title-button' innerClasses='is-small is-dark home-page__title-button-inner' onClick={this.handleEditParams} />
          </>
        } order={<OrderPreview />} blip={this.props.derived.totalItems}
      >
        <Section classes='home-page__section'>
          {
            filteredCategories.map((category, index) => (
              <div key={index}>
                <h1 className='title'>{category.name}</h1>
                <IconCards cards={category.items.map((item, index) => (
                  <IconCard
                    classes='home-page__menu-card'
                    imageClasses='button home-page__menu-card-image'
                    onClick={() => this.props.openMenuModal(item)}
                    image={item.image}
                    key={index}
                  >
                    <MenuItem item={item} blip={Math.floor(Math.random() * 3)} />
                  </IconCard>
                ))}
                />
              </div>
            ))
          }
        </Section>
        <div className='hero-foot home-page__foot'>
          © {currentYear} Andalous Mediterranean Grill, LLC, All&nbsp;Rights&nbsp;Reserved
          <br />
          The Andalous Logo is a registered trademark of Andalous Mediterranean Grill, LLC
          <br />
          Copying or duplication of images contained herein is strictly forbidden without the prior written consent of Andalous Mediterranean Grill, LLC
          <br />
          <Link to='/privacy-policy'>Privacy Policy</Link> | <Link to='/terms-of-service'>Terms of Service</Link>
        </div>
      </OrderLayout>
    )
  }
}

const SuperFancyComponentBecauseWeNeedAnEmptyLocationInputForBikesheddingReasons = (props) => {
  const emptyLocationForLiterallyNoGoodReason = {
    id: -1,
    name: 'Select a location'
  }

  const [modal, showModal] = useModal()

  const newProps = {
    ...props,
    showModal,
    locations: [emptyLocationForLiterallyNoGoodReason, ...(props.locations || [])]
  }

  return (
    <>
      <HomePage {...newProps} />
      {modal}
    </>
  )
}

export default withRouter(connect(
  state => ({
    name: state.order.name,
    location: state.order.location,
    email: state.order.email,
    phone: state.order.phone,
    derived: state.order.derived
  }),
  dispatch => ({
    loadDependenices: () => {
      dispatch(loadMenu())
      dispatch(loadLocations())
    },
    commitParams: (name, location, email, phone) => {
      dispatch(createAction('order.name', { name }))
      dispatch(createAction('order.location', { location }))
      dispatch(createAction('order.email', { email }))
      dispatch(createAction('order.phone', { phone }))
    },
    openMenuModal: (item) => dispatch(createAction('modal.open', { name: 'menu', data: { item } }))
  })
)(withDependencies(SuperFancyComponentBecauseWeNeedAnEmptyLocationInputForBikesheddingReasons, ['menu', 'locations'], { includeProps: true })))
