import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'

import { LoadingScreen } from '../../components'
import config from '../../config'
import { withDependencies } from '../../containers'
import createAction from '../../actions'

const loadOrder = (data, props) => {
  console.log(data)
  props.clearOrder()
  const rebuiltOrder = {
    firstname: data.name.first,
    lastname: data.name.last,
    address1: data.address.line1,
    address2: data.address.line2,
    city: data.address.city,
    zip: data.address.zip,
    comment: data.comment,
    location: data.location,
    date: data.date,
    email: data.email,
    fulfillment: data.fulfillment,
    company: data.company,
    phone: data.phone,
    time: data.time,
    tip: data.tip,
    tipFormat: 'custom',
    items: []
  }
  data.items.forEach(el => {
    const elementByCode = props.catering.byCode[el.code]
    if (elementByCode) {
      el.minusEnabled = true
      el.plusEnabled = true
      el.readOnly = false
      if (el.type === 'lunchbox') {
        el.compositeId = (el.id + Object.keys(el.items).reduce((acc, cur) => { return acc + el.items[cur].id }, 0)) * 1000
        const lunchboxItems = el.items
        delete el.items
        el.items = {}
        if (el.code === 'LBOX_SAND') {
          el.items.sandwich = lunchboxItems[0]
          el.items.salad = lunchboxItems[1]
          el.items.dip = lunchboxItems[2]
        } else {
          el.items.entree = lunchboxItems[0]
          el.items.side1 = lunchboxItems[1]
          el.items.side2 = lunchboxItems[2]
          el.items.side3 = lunchboxItems[3]
          el.items.dessert = lunchboxItems[4]
        }
        el.price = elementByCode.tier1
      } else {
        const tierInNumber = Number(el.tier.split('r')[1])
        el.tier = tierInNumber
        if (elementByCode.tier3 && (elementByCode.tier3 !== 0)) {
          el.description = ['Small Tray', 'Medium Tray', 'Large Tray'][el.tier - 1]
        } else if (el.code === 'CART_GYRO') {
          if (el.tier === 1) {
            el.description = 'Gyro Cart'
          } else {
            el.description = 'Chef Service'
          }
        } else if (el.code === 'BFST_LAMB') {
          if (el.tier === 1) {
            el.description = 'Medium Lamb'
          } else {
            el.description = 'Large Lamb'
          }
        } else if (el.code === 'STFF_SRVR') {
          if (el.tier === 1) {
            el.description = 'Number of Servers'
          } else {
            el.description = 'Total Hours'
          }
        } else {
          el.description = elementByCode.description || ''
        }
        el.compositeId = el.id * 10 + (el.tier - 1)
        el.minimum = elementByCode.minimum || 1
        el.price = elementByCode[`tier${el.tier}`]
      }
      rebuiltOrder.items.push(el)
    }
  })
  console.log(rebuiltOrder)
  props.duplicateOrder(rebuiltOrder)
  props.history.push('/catering/cart')
}

const Clone = props => {
  const [content, setContent] = useState(<LoadingScreen />)
  useEffect(() => {
    const errorMessage = (
      <div className='message is-danger'>
        <div className='message-header'>
          <p>ERROR: Order Could Not Be Loaded</p>
        </div>
        <div className='message-body'>
          Please close this tab and try again from the admin interface!<br />
          If this issue persists, please notify the developers!
        </div>
      </div>
    )
    const queryData = { token: decodeURIComponent(props.match.params.token) }

    axios.post(`${config.apiUrl}/catering/duplicate-order`, queryData)
      .then(res => {
        const orderData = res.data.orderConfig
        loadOrder(orderData, props)
      })
      .catch(e => {
        console.log(e)
        setContent(errorMessage)
      })
  }, [props.match.params.token]) // eslint-disable-line

  return (
    <>
      {content}
    </>
  )
}

export default connect(
  null,
  dispatch => ({
    duplicateOrder: (duplication) => dispatch(createAction('cateringOrder.duplicate', { duplication })),
    clearOrder: (output) => dispatch(createAction('cateringOrder.clear'))
  })
)(withDependencies(Clone, ['catering'], { includeProps: true }))
